import React from 'react';

import {Navigate, Outlet} from 'react-router-dom'

const useAuth=()=>{
  const user=localStorage.getItem('rmsAuthUser')
    // console.log("PublicRoutes==",user);
    if(user){
      // console.log("PublicRoutesif(user)==",user);
    return true
  } else {
    return false
  }
}

export const PublicRoutes = (props) =>{
    const auth=useAuth()
    return auth ? <Navigate to="/dashboard" /> : <Outlet />
}