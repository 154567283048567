import axios from 'axios';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import { Oval } from 'react-loader-spinner';

const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required')
});

export const ForgotPassword = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)

    const formik = useFormik({
        initialValues:{
            email: ''
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);            
        }
    });

    const handleSubmit = (values) => {
        setIsLoading(true)
        axios.post('/users/forgotPassword', values).then((res) =>{
            setIsLoading(false)
            if(res.data.success){
                Swal.fire(res.data.message, '', 'success')
                navigate('/login');
            }
            else{
                Swal.fire(res.data.message, '', 'error')
            }
        }).catch((error) => {
            Swal.fire(error.message, '', 'error')        
        });
    }

    return (
        <main>
            <div className="wrapper">
                {/* <ul className="bg-bubbles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul> */}
                <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4 rj-position">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-6">
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-center border-bottom pb-3">
                                            <Link to='/home' className="logo d-flex align-items-center w-auto">
                                                <img src="assets/img/logo1.jpg" alt="Talent Advisor" />
                                            </Link>
                                        </div>
                                        <div>
                                            <h5 className="card-title text-center mt-2 pb-0 fs-4">Forgot Password</h5>
                                            <p className="text-center small">Enter your email to reset your password</p>
                                        </div>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className='row'>
                                                <div className="col-12">
                                                    <label className="form-label">Email</label>
                                                    <div className="input-group has-validation">
                                                        <span className="input-group-text" id="inputGroupPrepend"><i className="bi bi-person"></i></span>
                                                        <input type="email" name="email" placeholder='Please enter your email' className="form-control" onChange={formik.handleChange} onBlur={formik.handleBlur} />                                                    
                                                    </div>
                                                    {formik.touched.email && formik.errors.email ? <div className="text-danger">{formik.errors.email}</div> : null}                                      
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-6">
                                                    {/* <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe" />
                                                        <label className="form-check-label" forHtml="rememberMe">Remember me</label>
                                                    </div> */}
                                                </div>
                                                <div className="col-6">
                                                    <p className="small text-right mt-1"><Link to='/login'> {"<<"} Back to Login</Link></p>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-0 text-center">
                                                <button type="submit" className={isLoading ? 'd-none' : 'r-btn r-btn-primary btn-block ft-uppercase'}>
                                                    <i className="bi bi-box-arrow-in-right ft-sm mr-2"></i> Submit
                                                </button>
                                                <Oval
                                                    height={35}
                                                    width={35}
                                                    color="#0d3f67"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={isLoading}
                                                    ariaLabel='oval-loading'
                                                    secondaryColor="#0d3f67"
                                                    strokeWidth={2}
                                                    strokeWidthSecondary={2}

                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    )
}