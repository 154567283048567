import React, { useEffect, useState }  from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import * as yup from 'yup';
import Swal from 'sweetalert2'
import { useFormik } from 'formik';
import { Oval } from 'react-loader-spinner';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const SendBulkEmail = ({show, setShow, checkedProfiles, ...rest}) => {    
    const [isLoading, setIsLoading] = useState(false)
    const [templates, setTemplates] = useState([])
    const [selectedTemplateId, setSelectedTemplateId] = useState(0)
    const handleClose = () => setShow(false);

    const [emailSubject, setEmailSubject] = useState('')
    const [emailBody, setEmailBody] = useState('')

    useEffect(() => {
        getList();
    }, []);

    const getList = async() => {
        await axios.get('/marketing_templates?type=Email')
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setTemplates(result.data)
            }
        })
    }

    useEffect(() => {
        getTemplateDetails()
    }, [selectedTemplateId]);

    const getTemplateDetails = async () => {
        if(selectedTemplateId > 0){
            await axios.get('/marketing_template?id='+selectedTemplateId)
            .then(function (response) {
                let result = response.data;
                if(result.success){
                    setEmailSubject(result.data.subject)
                    setEmailBody(result.data.body)
                }
            })
        }
        else{
            setEmailSubject('')
            setEmailBody('')
        }
    }

    const handleTemplateChange = (e) => {
        setSelectedTemplateId(e.currentTarget.value)
    }

    const sendEmail = async () => {
        if(emailSubject !== '' && emailBody !== ''){
            await axios.post('/marketing/send_email', {ids: checkedProfiles, subject: emailSubject, body: emailBody})
            .then(function (response) {
                let result = response.data;
                if(result.success){
                    Swal.fire(result.message, '', 'success').then(result => {
                        window.location.reload()
                    })  
                }
                else{
                    Swal.fire(result.message, '', 'error')
                }
            })
        }
    }

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-lg" style={{zIndex: 9999}}>
            <form autoComplete="off">
            <Modal.Header>
                <h5 className="modal-title">Send Bulk Email</h5>
                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                    {/* <span aria-hidden="true">&times;</span> */}
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group mb-2">
                    <select name='template' value={selectedTemplateId} className='form-control' onChange={handleTemplateChange}>
                        <option value='0'>Select Template</option>
                        {
                            templates.map(template => {
                                return(
                                    <option value={template.id}>{template.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                {
                    emailSubject !== '' ?
                    <div className="form-group mb-2">
                        <label className="form-label">Subject</label>
                        <input type='text' name='subject' className='form-control' value={emailSubject} readOnly/>
                    </div> : ''
                }

                {
                    emailBody !== '' ?
                    <div className="form-group mb-3">
                        <label className="form-label">Body</label>
                        <CKEditor
                            disabled
                            editor={ ClassicEditor }
                            data={emailBody}
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                // formik.setFieldValue('body', data)
                                console.log( { event, editor, data } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        />
                    </div> : ''
                }
                
                
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="ta-btn ta-btn-reverse" onClick={handleClose}>Close</button>
                <button type="button" className={isLoading ? 'd-none' : 'ta-btn ta-btn-primary'} onClick={sendEmail}>Send</button>
                <Oval
                    height={35}
                    width={35}
                    color="#0d3f67"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={isLoading}
                    ariaLabel='oval-loading'
                    secondaryColor="#0d3f67"
                    strokeWidth={2}
                    strokeWidthSecondary={2}

                />
            </Modal.Footer>
            </form>
        </Modal>
    );
}

export default SendBulkEmail;
SendBulkEmail.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired
};