import React, { useEffect, useState }  from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import * as yup from 'yup';
import Swal from 'sweetalert2'
import { useFormik } from 'formik';
import { Oval } from 'react-loader-spinner';

const validationSchema = yup.object({
    selectionProcessStatusId: yup
      .string('Please select the status')
      .required('Status is required'),
    requirementId: yup.string().when(['selectionProcessStatusId'], (selectionProcessStatusId, requirementIdSchema) => {
        if(selectionProcessStatusId == '7' || selectionProcessStatusId == '9'){
            return requirementIdSchema.required('Requirement is required')
        }
        else{
            return requirementIdSchema.notRequired()
        }
    }),
    interviewDate: yup.date().when(['selectionProcessStatusId'], (selectionProcessStatusId, interviewDateSchema) => {
        if(selectionProcessStatusId == '7' || selectionProcessStatusId == '9'){
            return interviewDateSchema.required('Interview Date is required')
        }
        else{
            return interviewDateSchema.notRequired()
        }
    }),
    interviewStartTime: yup.string().when(['selectionProcessStatusId'], (selectionProcessStatusId, interviewStartTimeSchema) => {
        if(selectionProcessStatusId == '7' || selectionProcessStatusId == '9'){
            return interviewStartTimeSchema.required('Interview Start Time is required')
        }
        else{
            return interviewStartTimeSchema.notRequired()
        }
    }),
    interviewEndTime: yup.string().when(['selectionProcessStatusId'], (selectionProcessStatusId, interviewEndTimeSchema) => {
        if(selectionProcessStatusId == '7' || selectionProcessStatusId == '9'){
            return interviewEndTimeSchema.required('Interview End Time is required')
        }
        else{
            return interviewEndTimeSchema.notRequired()
        }
    }),
    interviewMode: yup.string().when(['selectionProcessStatusId'], (selectionProcessStatusId, interviewModeSchema) => {
        if(selectionProcessStatusId == '7' || selectionProcessStatusId == '9'){
            return interviewModeSchema.required('Interview Mode is required')
        }
        else{
            return interviewModeSchema.notRequired()
        }
    }),
    interviewLocation: yup.string().when(['interviewMode'], (interviewMode, interviewLocationSchema) => {
        if(interviewMode == 'WalkIn'){
            return interviewLocationSchema.required('Interview Location is required')
        }
        else{
            return interviewLocationSchema.notRequired()
        }
    })
});

export const AddComment = ({show, setShow, getList, addCommentId, setAddCommentId, requirements, selectionProcessList, ...rest}) => {    

    const handleClose = () => setShow(false);
    const [isLoading, setIsLoading] = useState(false)
    const [isShowInterviewDate, setIsShowInterviewDate] = useState(false)

    useEffect(() => {
        formik.setValues(initialValues)
        if(addCommentId > 0){
            formik.setFieldValue('jobSeekerId', addCommentId)
        }
    },[addCommentId]);
    
    const initialValues = {
        jobSeekerId: addCommentId,
        selectionProcessStatusId: null,
        comment: null,
        requirementId: null,
        interviewDate: null,
        interviewStartTime: null,
        interviewEndTime: null,
        interviewMode: null,
        interviewLocation: null
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        }
    });

    const handleChangeSPStatus = async (e) => {     
        let statusId = e.currentTarget.value
        formik.setFieldValue('selectionProcessStatusId', statusId)
        if(statusId === '7' || statusId === '9'){
            setIsShowInterviewDate(true)
        }
        else{
            setIsShowInterviewDate(false)
        }        
    }

    const handleRequirementChange = (e) => {
        formik.setFieldValue('requirementId', e.currentTarget.value)
    }
    
    const handleSubmit = (values) =>{
        setIsLoading(true)
        axios.post(`saveSelectionProcessStatus`, values).then(res => {
            setIsLoading(false)
            if(res.data.success){
                setShow(false)                                 
                Swal.fire(res.data.message, '', 'success').then(result => {
                    // window.location.reload()
                    getList()                     
                })
            } 
            else{
                Swal.fire(res.data.message, '', 'error')
            }
            
        }).catch((error) => {
            // console.log(error.response.data)
            Swal.fire(error.response.data.message, '', 'error')
            setIsLoading(false)
        });  
    }

    const handleChangeInterviewMode = () => {

    }

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-md" style={{zIndex: 9999}}>
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Modal.Header>
                <h5 className="modal-title">Add Comment</h5>
                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                    {/* <span aria-hidden="true">&times;</span> */}
                </button>
            </Modal.Header>
            <Modal.Body>                
                <div className="form-group mb-2">
                    <label>Select Status</label>
                    <select name='selectionProcessStatusId' className='form-control' onChange={handleChangeSPStatus}>
                        <option value=''>Select Status</option>
                        {
                            selectionProcessList.map(selectionProcess => {
                                return(
                                    <option value={selectionProcess.id}>{selectionProcess.name}</option>
                                )
                            })
                        }
                    </select>
                    {formik.touched.selectionProcessStatusId && formik.errors.selectionProcessStatusId ? <div className="text-danger">{formik.errors.selectionProcessStatusId}</div> : null} 
                </div>
                <div className="form-group mb-3">
                    <label>Comment</label>
                    <textarea name="comment" className='form-control' onChange={formik.handleChange} onBlur={formik.handleBlur} ></textarea>
                </div>
                <div className="form-group mb-2">
                    <label>Select Requirement</label>
                    <select name='requirementId' className='form-control' onChange={handleRequirementChange}>
                        <option value=''>Select Requirement</option>
                        {
                            requirements.map(requirement => {
                                return(
                                    <option value={requirement.id}>{requirement.title}</option>
                                )
                            })
                        }
                    </select>
                    {formik.touched.requirementId && formik.errors.requirementId ? <div className="text-danger">{formik.errors.requirementId}</div> : null} 
                </div>
                {
                    isShowInterviewDate ? 
                    <>
                    <div className="form-group mb-2">
                        <label>Interview Date</label>
                        <input type='date' name='interviewDate' className='form-control' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.touched.interviewDate && formik.errors.interviewDate ? <div className="text-danger">{formik.errors.interviewDate}</div> : null} 
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="form-group mb-2">
                                <label>Interview Start Time</label>
                                <input type='time' name='interviewStartTime' className='form-control' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                {formik.touched.interviewStartTime && formik.errors.interviewStartTime ? <div className="text-danger">{formik.errors.interviewStartTime}</div> : null} 
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group mb-2">
                                <label>Interview End Time</label>
                                <input type='time' name='interviewEndTime' className='form-control' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                {formik.touched.interviewEndTime && formik.errors.interviewEndTime ? <div className="text-danger">{formik.errors.interviewEndTime}</div> : null} 
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-2">
                        <label>Interview Mode</label>
                        <select name='interviewMode' className='form-control' onChange={formik.handleChange} onBlur={formik.handleBlur}>
                            <option value=''>Select Mode</option>
                            <option value='WalkIn'>WalkIn</option>
                            <option value='Virtual'>Virtual</option>
                        </select>
                        {formik.touched.interviewMode && formik.errors.interviewMode ? <div className="text-danger">{formik.errors.interviewMode}</div> : null} 
                    </div>
                    {
                        formik.values.interviewMode === 'WalkIn' ?
                        <div className="form-group mb-2">
                            <label>Interview Location</label>
                            <input type='text' name='interviewLocation' className='form-control' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            {formik.touched.interviewLocation && formik.errors.interviewLocation ? <div className="text-danger">{formik.errors.interviewLocation}</div> : null} 
                        </div>
                        : ''
                    }
                    </>
                    : ''
                }
                
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="ta-btn ta-btn-reverse" onClick={handleClose}>Close</button>
                <button type="submit" className={isLoading ? 'd-none' : 'ta-btn ta-btn-primary'}>Save</button>
                <Oval
                    height={35}
                    width={35}
                    color="#0d3f67"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={isLoading}
                    ariaLabel='oval-loading'
                    secondaryColor="#0d3f67"
                    strokeWidth={2}
                    strokeWidthSecondary={2}

                />
            </Modal.Footer>
            </form>
        </Modal>
    );
}

export default AddComment;
AddComment.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    addCommentId: PropTypes.number.isRequired,
    setAddCommentId: PropTypes.func.isRequired
};