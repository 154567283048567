import React, { useEffect, useState }  from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import * as yup from 'yup';
import Swal from 'sweetalert2'
import { useFormik } from 'formik';
import { Oval } from 'react-loader-spinner';

const validationSchema = yup.object({
    name: yup
      .string('Enter your name')
      .required('Name is required'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    phone: yup
      .string('Enter your phone number')
      .required('Phone number is required')
});

export const Add = ({type, show, setShow, handleClose, getList, editId, setEditId, ...rest}) => {    

    const initialValues = {
        id: editId,
        name: '',
        email: '',
        phone: '',
        role: 'Recruiter'
    }

    const [isRecuriterLoading, setIsRecuriterLoading] = useState(false)

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        }
    });

    useEffect(() => {
        if(editId > 0){
            axios.get(`user?id=${editId}`).then(res => {
                console.log(res);
                if(res.data.success){
                    formik.setFieldValue('id', res.data.data.id)
                    formik.setFieldValue('name', res.data.data.name)
                    formik.setFieldValue('email', res.data.data.email)
                    formik.setFieldValue('phone', res.data.data.phone)
                    formik.setFieldValue('role', res.data.data.role)
                }
            })
        }
        else{
            formik.setFieldValue('id', 0)
            formik.setFieldValue('name', '')
            formik.setFieldValue('email', '')
            formik.setFieldValue('phone', '')
            formik.setFieldValue('role', 'Recruiter')
        }
        
    },[editId]);
    
    const handleSubmit = (values) =>{
        setIsRecuriterLoading(true)
        if(editId > 0){
            axios.put(`users/update`, values).then(res => {
                if(res.data.success){
                    setShow(false);                    
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        getList();                        
                    })
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
                setIsRecuriterLoading(false)
            }).catch((error) => {
                // console.log(error.response.data)
                Swal.fire(error.response.data.message, '', 'error')
                setIsRecuriterLoading(false)
            });
        }
        else{
            axios.post(`users/create`, values).then(res => {
                if(res.data.success){
                    setShow(false);
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        getList();
                    })                
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
                setIsRecuriterLoading(false)
            }).catch((error) => {
                // console.log(error.response.data)
                Swal.fire(error.response.data.message, '', 'error')
                setIsRecuriterLoading(false)
            });
        }      
    }
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-md" style={{zIndex: 9999}}>
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Modal.Header>
                <h5 className="modal-title">{type} User</h5>
                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                    {/* <span aria-hidden="true">&times;</span> */}
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group mb-2">
                    <label htmlFor="">Name</label>
                    <input name="name" type="text" className="form-control" value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                    {formik.touched.name && formik.errors.name ? <div className="text-danger">{formik.errors.name}</div> : null} 
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="">Email</label>
                    <input name="email" type="email" className="form-control" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                    {formik.touched.email && formik.errors.email ? <div className="text-danger">{formik.errors.email}</div> : null} 
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="">Phone No.</label>
                    <input name="phone" type="text" className="form-control" value={formik.values.phone} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                    {formik.touched.phone && formik.errors.phone ? <div className="text-danger">{formik.errors.phone}</div> : null} 
                </div>
                <div className="form-group mb-2">
                    <div class="form-check-inline">
                        <input className="form-check-input" type="radio" name="role" value={"Manager"} onClick={() => formik.setFieldValue('role', 'Manager')} checked={formik.values.role === 'Manager' ? true : false }/> &nbsp;
                        <label className="form-check-label" htmlFor="role1" onClick={() => formik.setFieldValue('role', 'Manager')}>
                            Manager
                        </label>
                    </div>
                    <div className="form-check-inline">
                        <input className="form-check-input" type="radio" name="role" value={"Recruiter"} onClick={() => formik.setFieldValue('role', 'Recruiter')} checked={formik.values.role === 'Recruiter' ? true : false } /> &nbsp;
                        <label className="form-check-label" htmlFor="role2" onClick={() => formik.setFieldValue('role', 'Recruiter')}>
                            Recruiter
                        </label>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="ta-btn ta-btn-reverse" onClick={handleClose}>Close</button>
                <button type="submit" className={isRecuriterLoading ? 'd-none' : 'ta-btn ta-btn-primary'}>{type === 'Add' ? 'Add' : 'Update'}</button>
                <Oval
                    height={35}
                    width={35}
                    color="#0d3f67"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={isRecuriterLoading}
                    ariaLabel='oval-loading'
                    secondaryColor="#0d3f67"
                    strokeWidth={2}
                    strokeWidthSecondary={2}

                />
            </Modal.Footer>
            </form>
        </Modal>
    );
}

export default Add;
Add.propTypes = {
    type: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired    
};