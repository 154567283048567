import React, { useEffect, useState } from 'react';
import { AdminLayout } from '../Layout/AdminLayout';
import { Table } from '../Common/Table';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'

export const MarketingTemplateList = () => {
    const authUser = localStorage.getItem("rmsAuthUser");
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    if(user && user.role === 'Recuriter'){
        navigate('/dashboard')
    }
    
    const [marketingTemplates, setMarketingTemplates] = useState([]);    

    useEffect(() => {
        getList();
      }, []);

    const getList = async() => {
        await axios.get('/marketing_templates')
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setMarketingTemplates(result.data)
            }
            else {
                // setIsError(true);
            }
        })
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) { 
                axios.delete(`/marketing_templates/delete?id=${id}`).then((res) =>{
                    if(res.data.success){                             
                        Swal.fire('Deleted!', '', 'success')           
                        setMarketingTemplates(marketingTemplates.filter((v, i) => v.id !== id));
                    } 
                }).catch((error) => {
                    console.log(error);                     
                    Swal.fire(error.message, '', 'info')           
                });
            } else if (result.isDenied) {
              Swal.fire('Cancelled!', '', 'info')
            }
        }) 
    }    

    const columns = [
        {
            name: 'Name',
            selector: row => (
                <p>{row.name}</p>
            ),
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => (
                <p>{row.type}</p>
            ),
            sortable: true,
        },
        {
            name: 'Action',
            button: true,
            cell: row => (
                <div>
                    <Link to={'/edit_template?id='+row.id} className="icon icon-sm icon-primary">
                        <FontAwesomeIcon icon={faEdit} />
                    </Link>
                    <span className="icon icon-sm icon-danger cursor-pointer"  onClick={() => handleDelete(row.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </span>
                </div>
            ),
        }
    ];

    return (
        <AdminLayout>
            <div class="row d-flex align-items-center mb-2">
                <div class="col-8">
                    <div class="pagetitle">
                        <h1>Templates</h1>
                    </div>
                </div>
                <div class="col-4 text-right">
                    <Link to='/add_template' class="ta-btn ta-btn-primary">
                        <FontAwesomeIcon icon={faPlus} /> <span>Add Template</span>
                    </Link>
                </div>
            </div>

            <div class="card recent-sales overflow-auto">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="content px-0">
                                <Table columns={columns} data={marketingTemplates}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )

}