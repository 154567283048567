import React, { useEffect, useState } from 'react';
import { AdminLayout } from '../Layout/AdminLayout';
import { Table } from '../Common/Table';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import Switcher from 'react-switcher-rc';

export const RequirementList = () => {
    const authUser = localStorage.getItem("rmsAuthUser");
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    if(user && user.role === 'Recuriter'){
        navigate('/dashboard')
    }
    
    const [requirements, setRequirements] = useState([]);    

    useEffect(() => {
        getList();
      }, []);

    const getList = async() => {
        await axios.get('/requirements')
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setRequirements(result.data)
            }
        })
    }

    const handleWebsiteStatus = (e, id) => {       
        var status = 1;
        if(e.target.checked === true) {
            status = 1
        }
        else {
            status = 0
        }
        axios.put(`/requirements/updateWebsiteStatus?id=${id}`, {status}).then(res => {
            if(res.data.success){
                getList()
                Swal.fire('Updated!', '', 'success')
            }
            else{
                Swal.fire('Try Again', '', 'error')
            }
        })
        .catch(err => {
        })
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) { 
                axios.delete(`/requirements/delete?id=${id}`).then((res) =>{
                    if(res.data.success){                             
                        Swal.fire('Deleted!', '', 'success')           
                        setRequirements(requirements.filter((v, i) => v.id !== id));
                    } 
                }).catch((error) => {
                    console.log(error);                     
                    Swal.fire(error.message, '', 'info')           
                });
            } else if (result.isDenied) {
              Swal.fire('Cancelled!', '', 'info')
            }
        }) 
    }    

    const columns = [
        {
            name: 'Title',
            selector: row => (
                <p>{row.title}</p>
            ),
            sortable: true,
        },
        {
            name: 'Job Title',
            selector: row => (
                <p>{row.jobTitle}</p>
            ),
            sortable: true,
        },
        {
            name: 'Client',
            selector: row => (
                <p>{row.client.name}</p>
            ),
            sortable: true,
        },
        {
            name: 'Employment Type',
            selector: row => (
                <p>{row.employmentType}</p>
            ),
            sortable: true,
        },
        {
            name: 'Applied Candidates',
            cell: row => (
                <Link to={`/applied_candidates?requirementId=${row.id}&title=${row.title}`}>{row.applied_candidates.length}</Link>
            ),
            sortable: true,
        },
        {
            name: 'Is Show Website',
            cell: row => (
                <Switcher
                    name="my-switcher"
                    onChange={(e) => handleWebsiteStatus(e, row.id)}
                    checked={row.isShowWebsite === 1 ? true : false}
                />
            ),
        },
        {
            name: 'Action',
            button: true,
            cell: row => (
                <div>
                    <Link to={'/applied_candidates?requirementId='+row.id+'&title='+row.title} className="icon icon-sm icon-success" title='Applied Candidates'>
                        <FontAwesomeIcon icon={faEye} />
                    </Link>
                    <Link to={'/edit_requirement?id='+row.id} className="icon icon-sm icon-primary" title='Edit'>
                        <FontAwesomeIcon icon={faEdit} />
                    </Link>
                    <span className="icon icon-sm icon-danger cursor-pointer" onClick={() => handleDelete(row.id)} title='Delete'>
                        <FontAwesomeIcon icon={faTrash} />
                    </span>
                </div>
            ),
        }
    ];

    return (
        <AdminLayout>
            <div class="row d-flex align-items-center mb-2">
                <div class="col-8">
                    <div class="pagetitle">
                        <h1>Requirements</h1>
                    </div>
                </div>
                <div class="col-4 text-right">
                    <Link to='/add_requirement' class="ta-btn ta-btn-primary">
                        <FontAwesomeIcon icon={faPlus} /> <span>Add Requirement</span>
                    </Link>
                </div>
            </div>

            <div class="card recent-sales overflow-auto">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="content px-0">
                                <Table columns={columns} data={requirements}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )

}