import React, { useEffect, useRef, useState }  from 'react';
import axios from "axios";
import * as yup from 'yup';
import Swal from 'sweetalert2'
import { useFormik } from 'formik';
import { AdminLayout } from '../Layout/AdminLayout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import S3 from 'react-aws-s3';
import { Oval } from 'react-loader-spinner';
window.Buffer = window.Buffer || require("buffer").Buffer;



// const MAX_FILE_SIZE = 512000; //500KB

// const validFileExtensions = { file: ['pdf','doc','docx'] };

// function isValidFileType(fileName, fileType) {
//   return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
// }

const isEmailExist = async (email) => {
    let urlParams = new URLSearchParams(window.location.search);
    let id = urlParams.get('id');
    let job_seeker = await axios.get(`job_seekers/isEmailExist?email=${email}&id=${id}`)
    
    if(job_seeker.data.success && job_seeker.data.isExist === false){
        return true            
    }
    else{
        return false 
    }
}

const isPhoneNumberExist = async (phone) => {   
    let urlParams = new URLSearchParams(window.location.search);
    let id = urlParams.get('id'); 
    let job_seeker = await axios.get(`job_seekers/isPhoneExist?phone=${phone}&id=${id}`)
    
    if(job_seeker.data.success && job_seeker.data.isExist === false){
        return true            
    }
    else{
        return false 
    }
}

const generateMonthOptions = () => {
    let options = []
    let months = [{value: "1", label: "January"},{value: "2", label: "Febrary"},{value: "3", label: "March"},{value: "4", label: "April"},{value: "5", label: "May"},{value: "6", label: "June"},{value: "7", label: "July"},{value: "8", label: "August"},{value: "9", label: "September"},{value: "10", label: "October"},{value: "11", label: "November"},{value: "12", label: "December"}]
    for(let i = 0; months.length > i; i++){
        options.push(<option value={months[i].value}>{months[i].label}</option>)
    }
    return options
}

const generateIntegerOptions = (min, max) => {
    let options = []
    for(let i = min; max >= i; i++){
        options.push(<option value={i}>{i}</option>)
    }
    return options
}

const validationSchema = yup.object({
    resume: yup
        .mixed()
        .required("Resume is required"),
        // .test("is-valid-type", "Not a valid file type", value => isValidFileType(value && value.name.toLowerCase(), "file")),
        // .test("is-valid-size", "Max allowed size is 500KB", value => value && value.size <= MAX_FILE_SIZE),
    name: yup
      .string('Enter the name')
      .required('Name is required'),
    email: yup
      .string('Enter the email')
      .email('Enter a valid email')
      .required('Email is required')
      .test('email check','email exists in the database', value => isEmailExist(value)),
    phone: yup
      .number('Enter the phone number')
      .required('Phone Number is required')
      .test('phone check','phone number exists in the database', value => isPhoneNumberExist(value)),
    keySkills: yup.array().min(1, 'Select at least one key skill').required('Key Skills are required'),
});

export const AddJobSeeker = () => {

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("id")
    const [isLoading, setIsLoading] = useState(false)
    const [locations, setLocations ] = useState([]);
    const [functionalAreas, setFunctionalAreas ] = useState([]);
    const [industries, setIndustries ] = useState([]);
    const [keySkills, setKeySkills ] = useState([]);

    let initialValues = {
        id: null,
        resume: null,
        name: '',
        email: '',
        alternateEmail: '',
        phone: '',
        alternatePhone: '',
        resumeHeadline: '',
        profileSummary: null,
        currCompanyDesgination: null,
        currCompanyName: null,
        currCompanyDurationMonthFrom: null,
        currCompanyDurationYearFrom: null,
        currCompanyJobProfile: '',
        prevEmployerDesgination: '',
        prevEmployerName: '',
        prevEmployerDurationMonthFrom: null,
        prevEmployerDurationYearFrom: null,
        prevEmployerDurationMonthTo: null,
        prevEmployerDurationYearTo: null,
        prevEmployerJobProfile: '',
        currentLocation: '',
        preferredLocations: [],
        functionalArea: '',
        functionalAreaRole: [],
        industry: '',
        keySkills: [],
        totalExpYear: null,
        totalExpMonth: null,
        annualSalaryLakhs: null,
        annualSalaryThousands: null,
        expectedCtcLakhs: null,
        expectedCtcThousands: null

    }

    useEffect(() => {
        if(id > 0){
            axios.get(`job_seeker?id=${id}`).then(res => {
                console.log(res);
                if(res.data.success){
                    if(res.data.data){
                        console.log("res.data.data",res.data.data)
                        // res.data.data.keySkills = JSON.parse(res.data.data.keySkills)
                        // res.data.data.preferredLocations = JSON.parse(res.data.data.preferredLocations)
                        formik.setValues(res.data.data)
                    }                
                }
            })        
        }
    },[id]);

    useEffect(() => {
        axios.get(`locationsForSelect`).then(res => {
            // console.log(res);
            if(res.data.success){
                setLocations(res.data.data)
            }
        })
        axios.get(`functionalAreasForSelect`).then(res => {
            // console.log(res);
            if(res.data.success){
                setFunctionalAreas(res.data.data)
            }
        })
        axios.get(`industriesForSelect`).then(res => {
            // console.log(res);
            if(res.data.success){
                setIndustries(res.data.data)
            }
        })
        // axios.get(`keySkillsForSelect`).then(res => {
        //     // console.log(res);
        //     if(res.data.success){
        //         setKeySkills(res.data.data)
        //     }
        // })
    }, [])

    const loadKeySkills = (value) => {
        return new Promise((resolve) => {
            axios.get(`keySkillsForSelect?name=${value}`).then(res => {
                // console.log(res);
                if(res.data.success){
                    resolve(res.data.data)
                }
            })
        })
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        }
    });

    const handleSubmit = (values) =>{
        setIsLoading(true)
        // console.log(values)
        // values.keySkills = JSON.stringify(values.keySkills)
        // values.industry = JSON.stringify(values.industry)
        // values.functionalArea = JSON.stringify(values.functionalArea)
        // values.currentLocation = JSON.stringify(values.currentLocation)
        // values.preferredLocations = JSON.stringify(values.preferredLocations)

        axios.post(`job_seekers/create`, values).then(res => {
            setIsLoading(false)
            if(res.data.success){
                Swal.fire(res.data.message, '', 'success').then(result => {
                    navigate('/candidate_profiles');
                })
            } 
            else{
                Swal.fire(res.data.message, '', 'error')
            }
        }).catch((error) => {
            // console.log(error.response.data)
            setIsLoading(false)
            Swal.fire(error.response.data.message, '', 'error')
        });
    }

    const S3Config = {
        bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
        dirName: 'Resume', /* optional */
        region: process.env.REACT_APP_S3_REGION,
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
        // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
    }

    const handleResumeChange = (e) => {
        let file = e.target.files[0];
        // console.log(file)
        let fileName = 'R_' + new Date().getTime()
        const ReactS3Client = new S3(S3Config);
        ReactS3Client.uploadFile(file, fileName)
            .then((data) => {                
                // console.log(data)
                if (data.status === 204 && data.location !== '') {
                    formik.setFieldValue('resume', data.location)                    
                    console.log("success")
                }                
            })
        
        // console.log(formik.values)
    }
    
    return (
        <AdminLayout>
            <div className="row mb-2">
                <div className="col-6">
                    <div className="pagetitle">
                        <h1>{id > 0 ? 'Edit' : 'Add'} Job Seeker</h1>
                    </div>
                </div>
            </div>

            <section className="section dashboard">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-xxl-12 col-md-8">
                                <div className="card recent-sales overflow-auto">
                                    <div className="card-body">

                                        <form className="g-3 mt-3" autoComplete="off" onSubmit={formik.handleSubmit} encType="multipart/form-data">
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Name<span className="text-danger">*</span></label>
                                                        <input type="text" name="name" className="form-control" value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                        {formik.touched.name && formik.errors.name ? <div className="text-danger">{formik.errors.name}</div> : null} 
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Resume Headline</label>
                                                        <textarea name="resumeHeadline" style={{height: '100px'}} className="form-control" value={formik.values.resumeHeadline} onChange={formik.handleChange} onBlur={formik.handleBlur}></textarea>
                                                        {formik.touched.resumeHeadline && formik.errors.resumeHeadline ? <div className="text-danger">{formik.errors.resumeHeadline}</div> : null} 
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <input type='file' name='resume' onChange={(e) => handleResumeChange(e) }/>
                                                    {formik.touched.resume && formik.errors.resume ? <div className="text-danger">{formik.errors.resume}</div> : null} 
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Email<span className="text-danger">*</span></label>
                                                        <input type="text" name="email" className="form-control" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                        {formik.touched.email && formik.errors.email ? <div className="text-danger">{formik.errors.email}</div> : null} 
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Alternate Email</label>
                                                        <input type="text" name="alternateEmail" className="form-control" value={formik.values.alternateEmail} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                        {formik.touched.alternateEmail && formik.errors.alternateEmail ? <div className="text-danger">{formik.errors.alternateEmail}</div> : null} 
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Phone Number<span className="text-danger">*</span></label>
                                                        <input type="text" name="phone" className="form-control" value={formik.values.phone} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                        {formik.touched.phone && formik.errors.phone ? <div className="text-danger">{formik.errors.phone}</div> : null} 
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Alternate Phone Number</label>
                                                        <input type="text" name="alternatePhone" className="form-control" value={formik.values.alternatePhone} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                        {formik.touched.alternatePhone && formik.errors.alternatePhone ? <div className="text-danger">{formik.errors.alternatePhone}</div> : null} 
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Current Location</label>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            placeholder="Select and type location"
                                                            value={formik.values.currentLocation}
                                                            // isDisabled={isDisabled}
                                                            // isLoading={isLoading}
                                                            isClearable={true}
                                                            // isRtl={isRtl}
                                                            // isMulti={true}
                                                            isSearchable={true}
                                                            name="currentLocation"
                                                            options={locations}
                                                            onChange={(value) => {value ? formik.setFieldValue('currentLocation', value) : formik.setFieldValue('currentLocation', '')}}
                                                        />
                                                        {/* {formik.touched.jobLocations && formik.errors.jobLocations ? <div className="text-danger">{formik.errors.jobLocations}</div> : null} */}
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Preferred Locations</label>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            placeholder="Select and type location"
                                                            value={formik.values.preferredLocations}
                                                            // isDisabled={isDisabled}
                                                            // isLoading={isLoading}
                                                            isClearable={true}
                                                            // isRtl={isRtl}
                                                            isMulti={true}
                                                            isSearchable={true}
                                                            name="preferredLocations"
                                                            options={locations}
                                                            onChange={(value) => {value ? formik.setFieldValue('preferredLocations', value) : formik.setFieldValue('preferredLocations', [])}}
                                                        />
                                                        {/* {formik.touched.jobLocations && formik.errors.jobLocations ? <div className="text-danger">{formik.errors.jobLocations}</div> : null} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>                                                
                                                <div className='col-md-3'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Functional Area</label>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            placeholder="Select and type functional area"
                                                            value={formik.values.functionalArea}
                                                            // isDisabled={isDisabled}
                                                            // isLoading={isLoading}
                                                            isClearable={true}
                                                            // isRtl={isRtl}
                                                            isSearchable={true}
                                                            name="functionalArea"
                                                            options={functionalAreas}
                                                            onChange={(value) => {value ? formik.setFieldValue('functionalArea', value) : formik.setFieldValue('functionalArea', '')}}
                                                        />
                                                        {/* {formik.touched.functionalArea && formik.errors.functionalArea ? <div className="text-danger">{formik.errors.functionalArea}</div> : null} */}
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Role</label>
                                                        <input type="text" name="functionalAreaRole" className="form-control" value={formik.values.role} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                        {/* {formik.touched.role && formik.errors.role ? <div className="text-danger">{formik.errors.role}</div> : null}  */}
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Industry</label>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            placeholder="Select and type industry"
                                                            value={formik.values.industry}
                                                            // isDisabled={isDisabled}
                                                            // isLoading={isLoading}
                                                            isClearable={true}
                                                            // isRtl={isRtl}
                                                            isSearchable={true}
                                                            name="industry"
                                                            options={industries}
                                                            onChange={(value) => {value ? formik.setFieldValue('industry', value) : formik.setFieldValue('industry', '')}}
                                                        />
                                                        {/* {formik.touched.industry && formik.errors.industry ? <div className="text-danger">{formik.errors.industry}</div> : null} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Key Skills<span className="text-danger">*</span></label>
                                                <AsyncSelect
                                                    cacheOptions
                                                    defaultOptions
                                                    value={formik.values.keySkills}
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    placeholder="Type and select key skills"
                                                    // isDisabled={isDisabled}
                                                    isLoading={false}
                                                    isClearable={true}
                                                    // isRtl={isRtl}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                    name="keySkills"
                                                    loadOptions={(e)=>loadKeySkills(e)}
                                                    onChange={(value) => {value ? formik.setFieldValue('keySkills', value) : formik.setFieldValue('keySkills', [])}}
                                                />
                                                {formik.touched.keySkills && formik.errors.keySkills ? <div className="text-danger">{formik.errors.keySkills}</div> : null}
                                            </div>                                          
                                            <div className="mb-3">
                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <label className="form-label">Total Experience (Years)</label>
                                                        <select name="totalExpYear" className="form-select" value={formik.values.totalExpYear} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                            <option>Select</option>                                                            
                                                            <option value='0'>0</option>
                                                            <option value='1'>1</option>
                                                            <option value='2'>2</option>
                                                            <option value='3'>3</option>
                                                            <option value='4'>4</option>
                                                            <option value='5'>5</option>
                                                            <option value='6'>6</option>
                                                            <option value='7'>7</option>
                                                            <option value='8'>8</option>
                                                            <option value='9'>9</option>
                                                            <option value='10'>10</option>
                                                            <option value='11'>11</option>
                                                            <option value='12'>12</option>
                                                            <option value='13'>13</option>
                                                            <option value='14'>14</option>
                                                            <option value='15'>15</option>
                                                            <option value='16'>16</option>
                                                            <option value='17'>17</option>
                                                            <option value='18'>18</option>
                                                            <option value='19'>19</option>
                                                            <option value='20'>20</option>
                                                            <option value='21'>21</option>
                                                            <option value='22'>22</option>
                                                            <option value='23'>23</option>
                                                            <option value='24'>24</option>
                                                            <option value='25'>25</option>
                                                            <option value='26'>26</option>
                                                            <option value='27'>27</option>
                                                            <option value='28'>28</option>
                                                            <option value='29'>29</option>
                                                            <option value='30'>30</option>
                                                            <option value='31'>30+</option>
                                                        </select>
                                                        {formik.touched.totalExpYear && formik.errors.totalExpYear ? <div className="text-danger">{formik.errors.totalExpYear}</div> : null}
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label className="form-label">Total Experience (Months)</label>
                                                        <select name="totalExpMonth" className="form-select" value={formik.values.totalExpMonth} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                            <option>Select</option>                                              
                                                            <option value='0'>0</option>
                                                            <option value='1'>1</option>
                                                            <option value='2'>2</option>
                                                            <option value='3'>3</option>
                                                            <option value='4'>4</option>
                                                            <option value='5'>5</option>
                                                            <option value='6'>6</option>
                                                            <option value='7'>7</option>
                                                            <option value='8'>8</option>
                                                            <option value='9'>9</option>
                                                            <option value='10'>10</option>
                                                            <option value='11'>11</option>
                                                            <option value='12'>12</option>
                                                        </select>
                                                        {formik.touched.totalExpMonth && formik.errors.totalExpMonth ? <div className="text-danger">{formik.errors.totalExpMonth}</div> : null}
                                                    </div>
                                                </div>                                                
                                            </div>
                                            <div className="mb-3">
                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <label className="form-label">Annual Salary (Lakhs)</label>
                                                        <select name="annualSalaryLakhs" className="form-select" value={formik.values.annualSalaryLakhs} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                            <option>Select</option>                                                            
                                                            {generateIntegerOptions(0, 100)}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label className="form-label">Annual Salary (Thousands)</label>
                                                        <select name="annualSalaryThousands" className="form-select" value={formik.values.annualSalaryThousands} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                            <option>Select</option>                                              
                                                            {generateIntegerOptions(0, 99)}
                                                        </select>
                                                    </div>
                                                </div>                                                
                                            </div>
                                            <div className="mb-3">
                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <label className="form-label">Expected CTC (Lakhs)</label>
                                                        <select name="expectedCtcLakhs" className="form-select" value={formik.values.expectedCtcLakhs} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                            <option>Select</option>                                                            
                                                            {generateIntegerOptions(0, 100)}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label className="form-label">Expected CTC (Thousands)</label>
                                                        <select name="expectedCtcThousands" className="form-select" value={formik.values.expectedCtcThousands} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                            <option>Select</option>                                              
                                                            {generateIntegerOptions(0, 99)}
                                                        </select>
                                                    </div>
                                                </div>                                                
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Profile Summary</label>
                                                <textarea name="profileSummary" style={{height: '100px'}} className="form-control" value={formik.values.profileSummary} onChange={formik.handleChange} onBlur={formik.handleBlur}></textarea>
                                                {formik.touched.profileSummary && formik.errors.profileSummary ? <div className="text-danger">{formik.errors.profileSummary}</div> : null} 
                                            </div>

                                            <h5 className='mt-4 mb-3'>Employer Details</h5>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Current Company Designation</label>
                                                        <input type="text" name="currCompanyDesgination" className="form-control" value={formik.values.currCompanyDesgination} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                        {/* {formik.touched.currCompanyDesgination && formik.errors.currCompanyDesgination ? <div className="text-danger">{formik.errors.currCompanyDesgination}</div> : null}  */}
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Current Company</label>
                                                        <input type="text" name="currCompanyName" className="form-control" value={formik.values.currCompanyName} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                        {/* {formik.touched.currCompanyName && formik.errors.currCompanyName ? <div className="text-danger">{formik.errors.currCompanyName}</div> : null}  */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <div className="mb-3">
                                                                <label className="form-label">From Month (Current Company)</label>
                                                                <select className='form-control' name='currCompanyDurationMonthFrom' value={formik.values.currCompanyDurationMonthFrom} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                                    <option>Select Month</option>
                                                                    {generateMonthOptions()}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className="mb-3">
                                                                <label className="form-label">From Year (Current Company)</label>
                                                                <select className='form-control' name='currCompanyDurationYearFrom' value={formik.values.currCompanyDurationYearFrom} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                                    <option>Select Year</option>
                                                                    {generateIntegerOptions(1980,2023)}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Current Company Job Profile</label>
                                                <textarea name="currCompanyJobProfile" style={{height: '100px'}} className="form-control" value={formik.values.currCompanyJobProfile} onChange={formik.handleChange} onBlur={formik.handleBlur}></textarea>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Previous Company Designation</label>
                                                        <input type="text" name="prevEmployerDesgination" className="form-control" value={formik.values.prevEmployerDesgination} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Previous Company</label>
                                                        <input type="text" name="prevEmployerName" className="form-control" value={formik.values.prevEmployerName} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <div className="mb-3">
                                                                <label className="form-label">From Month (Previous Company)</label>
                                                                <select className='form-control' name='prevEmployerDurationMonthFrom' value={formik.values.prevEmployerDurationMonthFrom} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                                    <option>Select Month</option>
                                                                    {generateMonthOptions()}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className="mb-3">
                                                                <label className="form-label">From Year (Previous Company)</label>
                                                                <select className='form-control' name='prevEmployerDurationYearFrom' value={formik.values.prevEmployerDurationYearFrom} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                                    <option>Select Year</option>
                                                                    {generateIntegerOptions(1980,2023)}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <div className="mb-3">
                                                                <label className="form-label">To Month (Previous Company)</label>
                                                                <select className='form-control' name='prevEmployerDurationMonthTo' value={formik.values.prevEmployerDurationMonthTo} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                                    <option>Select Month</option>
                                                                    {generateMonthOptions()}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className="mb-3">
                                                                <label className="form-label">To Year (Previous Company)</label>
                                                                <select className='form-control' name='prevEmployerDurationYearTo' value={formik.values.prevEmployerDurationYearTo} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                                    <option>Select Year</option>
                                                                    {generateIntegerOptions(1980,2023)}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Previous Company Job Profile</label>
                                                <textarea name="prevEmployerJobProfile" style={{height: '100px'}} className="form-control" value={formik.values.prevEmployerJobProfile} onChange={formik.handleChange} onBlur={formik.handleBlur}></textarea>
                                            </div>


                                            
                                            <div className="row mt-3">
                                                <div className="text-end">
                                                    <button type='submit' className={isLoading ? 'd-none' : 'ta-btn ta-btn-primary text-center'}> <i className="bi bi-save mr-2"></i> <span> Save</span> </button>
                                                    <Oval
                                                        height={40}
                                                        width={40}
                                                        color="#0d3f67"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={isLoading}
                                                        ariaLabel='oval-loading'
                                                        secondaryColor="#0d3f67"
                                                        strokeWidth={2}
                                                        strokeWidthSecondary={2}

                                                    />
                                                </div>
                                            </div>                                            
                                        </form>
                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </AdminLayout>
    );
}