import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

export const AdminHeader = () => {
    const navigate = useNavigate();
    const authUser = localStorage.getItem("rmsAuthUser");
    const user = JSON.parse(authUser);

    const handleLogout = () => {
        localStorage.clear();
        navigate("/");
    };

    const handleToogleSidebar = () => {
        document.getElementsByTagName("BODY")[0].classList.toggle("toggle-sidebar");
    }

    return (
    <header id="header" className="header fixed-top d-flex align-items-center">
        <div className="d-flex align-items-center justify-content-between">
            <Link to='/home' className="logo d-flex align-items-center">
                <img src="assets/img/logo.png" alt="Talent Advisors" />
            </Link>
            <FontAwesomeIcon icon={faBars} className="toggle-sidebar-btn" onClick={() => handleToogleSidebar()}/>
            {/* <i className="bi bi-list toggle-sidebar-btn" onClick={() => handleToogleSidebar()}></i> */}
        </div>
        <nav className="header-nav ms-auto">
            <ul className="d-flex align-items-center">
                <li className="nav-item dropdown pe-3">
                    <a className="nav-link nav-profile d-flex align-items-center pe-0" data-bs-toggle="dropdown">
                        <img src="assets/img/profile-img.png" alt="Profile" className="rounded-circle" />
                        <span className="d-none d-md-block dropdown-toggle ps-2">{user.name}</span>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                        <li className="dropdown-header">
                            <h6>{user.name}</h6>
                            <span>{user.role}</span>
                        </li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>
                        <li>
                            <Link to="/profile" className="dropdown-item d-flex align-items-center">
                                <i className="bi bi-gear"></i>
                                <span>Profile</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/change_password" className="dropdown-item d-flex align-items-center">
                                <i className="bi bi-lock"></i>
                                <span>Change Password</span>
                            </Link>
                        </li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>

                        <li>
                            <a className="dropdown-item d-flex align-items-center" onClick={handleLogout}>
                                <i className="bi bi-power"></i>
                                <span>Sign Out</span>
                            </a>
                        </li>

                    </ul>
                </li>
            </ul>
        </nav>
    </header>
  );
}