import React from 'react';
import PropTypes from "prop-types";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import axios from 'axios';

export const ExportToExcel = ({checkedProfiles, ...rest}) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const getExportData = async () => {
        return new Promise(async (resolve) => {
            let response = await axios.get('/job_seekers/exportData?ids='+checkedProfiles)
            
            if(response.data.success){
                resolve(response.data.data)
            }
            else{
                resolve('')
            }
        })
        
    }

    const exportToCSV = async () => {
        let exportData = await getExportData()
        if(exportData !== ''){
            const ws = XLSX.utils.json_to_sheet(exportData);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, 'Profiles' + fileExtension);
        }
        
    };

    return (
        <button className="dropdown-item" type="button" onClick={(e) => exportToCSV()}>Download Selected Profile(s)</button>
    );
}

export default ExportToExcel;

ExportToExcel.propTypes = {
    checkedProfiles: PropTypes.array.isRequired
};