import React from 'react';
import PropTypes from "prop-types";
import DataTable from 'react-data-table-component';

export const Table = ({columns, data, ...rest}) => {
    return (
        <DataTable
            columns={columns}
            data={data}
            pagination
        />       
        
    );
}

export default Table;

Table.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired
};