import { AdminLayout } from "../Layout/AdminLayout";
import axios from "axios";
import { useFormik } from "formik";
import * as yup from 'yup';
import Swal from 'sweetalert2'
import { Oval } from "react-loader-spinner";
import { useState } from "react";

const validationSchema = yup.object({
    oldPassword: yup
      .string('Enter your old password')
      .required('Old Password is required'),
    newPassword: yup
      .string('Enter your new password')
      .required('New Password is required'),
    confirmPassword: yup
      .string('Enter your confirm password')
      .required('Confirm Password is required')
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
});

export const ChangePassword = () => {
    const [isChangePassword, setIsChangePassword] = useState(false)
    let initialValues = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        }
    });

    const handleSubmit = (values) =>{
        setIsChangePassword(true)
        axios.put(`users/changePassword`, values).then(res => {
            if(res.data.success){               
                Swal.fire(res.data.message, '', 'success').then(result => {
                    window.location.reload()                   
                })
            } 
            else{
                Swal.fire(res.data.message, '', 'error')
            }
            setIsChangePassword(false)
        }).catch((error) => {
            // console.log(error.response.data)
            Swal.fire(error.response.data.message, '', 'error')
            setIsChangePassword(false)
        });    
    }

    return (
        <AdminLayout>
            <div class="row d-flex align-items-center mb-2">
                <div class="col-8">
                    <div class="pagetitle">
                        <h1>Change Password</h1>
                    </div>
                </div>
                <div class="col-4 text-right">
                    
                </div>
            </div>
            <section className="section profile">
                <div className="row">
                    <div className="col-xl-8">
                        <div className="card">
                            <div className="card-body pt-3">
                                <form autoComplete="off" onSubmit={formik.handleSubmit}>
                                    <div className="row mb-3">
                                        <label htmlFor="currentPassword" className="col-md-4 col-lg-4 col-form-label">Current Password</label>
                                        <div className="col-md-8 col-lg-8">
                                            <input name="oldPassword" type="password" className="form-control" id="oldPassword" value={formik.values.oldPassword} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                            {formik.touched.oldPassword && formik.errors.oldPassword ? <div className="text-danger">{formik.errors.oldPassword}</div> : null}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="newPassword" className="col-md-4 col-lg-4 col-form-label">New Password</label>
                                        <div className="col-md-8 col-lg-8">
                                            <input name="newPassword" type="password" className="form-control" id="newPassword" value={formik.values.newPassword} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                            {formik.touched.newPassword && formik.errors.newPassword ? <div className="text-danger">{formik.errors.newPassword}</div> : null}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="confirmPassword" className="col-md-4 col-lg-4 col-form-label">Re-enter New Password</label>
                                        <div className="col-md-8 col-lg-8">
                                            <input name="confirmPassword" type="password" className="form-control" id="confirmPassword" value={formik.values.confirmPassword} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                            {formik.touched.confirmPassword && formik.errors.confirmPassword ? <div className="text-danger">{formik.errors.confirmPassword}</div> : null}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-lg-4"></div>
                                        <div className="col-md-8 col-lg-8">
                                            <button type="submit" className={isChangePassword ? 'd-none' : 'ta-btn ta-btn-primary'}>Change Password</button>
                                            <Oval
                                                height={35}
                                                width={35}
                                                color="#0d3f67"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={isChangePassword}
                                                ariaLabel='oval-loading'
                                                secondaryColor="#0d3f67"
                                                strokeWidth={2}
                                                strokeWidthSecondary={2}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </AdminLayout>
    )
}