import axios from 'axios';
axios.defaults.baseURL = 'https://api.talentadvisors.in';
// axios.defaults.baseURL = 'http://localhost:3010';

axios.interceptors.request.use(
    async (config) => {
      const authUser = await localStorage.getItem("rmsAuthUser");
      const user = JSON.parse(authUser);
      if (user) {
        const token = user.token;
        // config.headers.accessToken = token;
        if(config.url.includes('job_seekers/uploadViaCsv')){
          console.log("config.url",config.url);
          config.headers = {
            "Content-Type": "multipart/form-data",
            // "Content-Type": "application/json",
            "x-access-token": token,
          };
        }
        else{
          config.headers = {
            "Content-Type": "application/json",
            "x-access-token": token,
          };
        }
      } else {
        config.headers = { "Content-Type": "application/json" };
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );