import React, { useEffect, useState }  from 'react';
import axios from "axios";
import * as yup from 'yup';
import Swal from 'sweetalert2'
import { useFormik } from 'formik';
import { AdminLayout } from '../Layout/AdminLayout';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate, useSearchParams } from 'react-router-dom';

const validationSchema = yup.object({
    type: yup.string('Select the type').required('Type is required'),
    name: yup.string('Enter the name').required('Name is required'),
    subject: yup.string('Enter the subject').required('Subject is required'),
    body: yup.string('Enter the body').required('Body is required')
});

export const AddMarketingTemplate = () => {
    const authUser = localStorage.getItem("rmsAuthUser");
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    if(user && user.role === 'Recuriter'){
        navigate('/dashboard')
    }
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("id")

    let initialValues = {
        id: null,
        name: '',
        subject: '',
        body: '',
        type: ''
    }

    useEffect(() => {
        axios.get(`marketing_template?id=${id}`).then(res => {
            console.log(res);
            if(res.data.success){
                if(res.data.data){
                    formik.setValues(res.data.data)
                }
                
            }
        })        
    },[id > 0]);  
    
    useEffect(() => {
        
    }, [])

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        }
    });

    const handleSubmit = (values) =>{
        axios.post(`marketing_templates/create`, values).then(res => {
            if(res.data.success){
                Swal.fire(res.data.message, '', 'success').then(result => {
                    navigate('/templates');
                })                
            } 
            else{
                Swal.fire(res.data.message, '', 'error')
            }
        }).catch((error) => {
            // console.log(error.response.data)
            Swal.fire(error.response.data.message, '', 'error')
        });
    }
    
    return (
        <AdminLayout>
            <div className="row mb-2">
                <div className="col-6">
                    <div className="pagetitle">
                        <h1>{id ? 'Edit' : 'Add'} Template</h1>
                    </div>
                </div>
            </div>

            <section className="section dashboard">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-xxl-12 col-md-8">
                                <div className="card recent-sales overflow-auto">
                                    <div className="card-body">

                                        <form className="g-3 mt-3" autoComplete="off" onSubmit={formik.handleSubmit}>
                                            <div className="mb-3">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label className="form-label">Type<span className="text-danger">*</span></label>
                                                        <select name="type" className="form-control" value={formik.values.type} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                            <option>Select Type</option>
                                                            <option value='Email'>Email</option>
                                                            <option value='SMS'>SMS</option>
                                                            <option value='Whatsapp'>Whatsapp</option>
                                                        </select>
                                                        {formik.touched.type && formik.errors.type ? <div className="text-danger">{formik.errors.type}</div> : null} 
                                                    </div>
                                                </div>                                                
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Name<span className="text-danger">*</span></label>
                                                        <input type="text" name="name" className="form-control" value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                        {formik.touched.name && formik.errors.name ? <div className="text-danger">{formik.errors.name}</div> : null} 
                                                    </div>
                                                </div>
                                                <div className='col-md-6'></div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Subject<span className="text-danger">*</span></label>
                                                        <input type="text" name="subject" className="form-control" value={formik.values.subject} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                        {formik.touched.subject && formik.errors.subject ? <div className="text-danger">{formik.errors.subject}</div> : null} 
                                                    </div>
                                                </div>
                                                <div className='col-md-6'></div>
                                            </div>                                         
                                            <div className="mb-3">
                                                <label className="form-label">Body<span className="text-danger">*</span></label>
                                                <CKEditor
                                                    editor={ ClassicEditor }
                                                    data={formik.values.body}
                                                    onReady={ editor => {
                                                        // You can store the "editor" and use when it is needed.
                                                        console.log( 'Editor is ready to use!', editor );
                                                    } }
                                                    onChange={ ( event, editor ) => {
                                                        const data = editor.getData();
                                                        formik.setFieldValue('body', data)
                                                        console.log( { event, editor, data } );
                                                    } }
                                                    onBlur={ ( event, editor ) => {
                                                        console.log( 'Blur.', editor );
                                                    } }
                                                    onFocus={ ( event, editor ) => {
                                                        console.log( 'Focus.', editor );
                                                    } }
                                                />
                                                {formik.touched.body && formik.errors.body ? <div className="text-danger">{formik.errors.body}</div> : null} 
                                            </div>                                            
                                            <div className="row mt-3">
                                                <div className="text-end">
                                                    <button type='submit' className="ta-btn ta-btn-primary text-center"> <i className="bi bi-save mr-2"></i> <span> Save</span> </button>
                                                </div>
                                            </div>                                      
                                        </form>
                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </AdminLayout>
    );
}