import React from "react";
import { Link, useLocation } from "react-router-dom";

export const AdminSidebar = () => {
    const location = useLocation();
    const authUser = localStorage.getItem("rmsAuthUser");
    const user = JSON.parse(authUser);

    return (
        <aside id="sidebar" className="sidebar">
            <ul className="sidebar-nav" id="sidebar-nav">
                <li className="nav-item">
                    <Link to='/dashboard' className={location.pathname === "/dashboard"? "nav-link" : "nav-link collapsed"}>
                        <i className="bi bi-grid"></i>
                        <span>Dashboard</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to='/candidate_profiles' className={location.pathname === "/candidate_profiles"? "nav-link" : "nav-link collapsed"}>
                        <i className="bi bi-people"></i>
                        <span>Candidate Profiles</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to='/favourite_profiles' className={location.pathname === "/favourite_profiles"? "nav-link" : "nav-link collapsed"}>
                        <i className="bi bi-people"></i>
                        <span>Favourite Profiles</span>
                    </Link>
                </li>
                {
                    user.role === 'Admin' ? 
                    <>                    
                    <li className="nav-item">
                        <Link to='/requirements' className={location.pathname === "/requirements"? "nav-link" : "nav-link collapsed"}>
                            <i className="bi bi-people"></i>
                            <span>Requirements</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/clients' className={location.pathname === "/clients"? "nav-link" : "nav-link collapsed"}>
                            <i className="bi bi-people"></i>
                            <span>Clients</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/templates' className={location.pathname === "/templates"? "nav-link" : "nav-link collapsed"}>
                            <i className="bi bi-people"></i>
                            <span>Templates</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/users' className={location.pathname === "/users"? "nav-link" : "nav-link collapsed"}>
                            <i className="bi bi-people"></i>
                            <span>Users</span>
                        </Link>
                    </li>
                    {/* <li className="nav-item">
                        <Link to='/requirements' className={location.pathname === "/requirements" || "/add_requirement" ? "nav-link" : "nav-link collapsed"}>
                            <i className="bi bi-bar-chart"></i><span>Requirements</span><i className="bi bi-chevron-down ms-auto"></i>
                        </Link>
                        <ul className={location.pathname === "/requirements" || "/add_requirement" ? "nav-content collapse show" : "nav-content collapse"}>
                            <li>
                                <Link to='/requirements' className={location.pathname === "/requirements"? "active" : ""}>
                                    <i className="bi bi-circle"></i><span>All Requirements</span>
                                </Link>
                            </li>
                            <li>
                                <Link to='/add_requirement' className={location.pathname === "/add_requirement"? "active" : ""}>
                                    <i className="bi bi-circle"></i><span>Add Requirement</span>
                                </Link>
                            </li>
                        </ul>
                    </li> */}
                    </>  
                    : ''
                }
                {
                    user.role === 'Manager' ? 
                    <li className="nav-item">
                        <Link to='/requirements' className={location.pathname === "/requirements"? "nav-link" : "nav-link collapsed"}>
                            <i className="bi bi-people"></i>
                            <span>Requirements</span>
                        </Link>
                    </li>
                    : ''
                }
                
                {/* <li className="nav-item">
                    <Link to='/jobs' className="nav-link collapsed">
                        <i className="bi bi-people"></i>
                        <span>Jobs</span>
                    </Link>
                </li> */}
                        
            </ul>
        </aside>
    );
}