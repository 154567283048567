import React from 'react';

import {Navigate, Outlet} from 'react-router-dom'

const useAuth=()=>{
  const user = localStorage.getItem('rmsAuthUser')
  // console.log("ProtectedRoutes==",user);
  if(user){
    // console.log("ProtectedRoutesif(user)==",user);
    return true
  } else {
    return false
  }
}

export const ProtectedRoutes=(props) =>{
    const auth = useAuth()
    
    return auth ? <Outlet />: <Navigate to="/"/>
}