import React, { useEffect, useState }  from 'react';
import axios from "axios";
import * as yup from 'yup';
import Swal from 'sweetalert2'
import { useFormik } from 'formik';
import { AdminLayout } from '../Layout/AdminLayout';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

const validationSchema = yup.object({
    title: yup.string('Enter the title').required('Title is required'),
    jobTitle: yup.string('Enter the job title').required('Job Title is required'),
    employmentType: yup.string('Select the employment type').required('Employment Type is required'),
    jobDescription: yup.string('Enter the job description').required('Job Description is required'),
    minimumSalary: yup.number('Enter only number').required('Minimum Salary is required'),
    maximumSalary: yup.number('Enter only number').required('Maximum Salary is required'),
    minimumExperience: yup.number('Enter only number').required('Minimum Experience is required'),
    maximumExperience: yup.number('Enter only number').required('Maximum Experience is required'),
    keySkills: yup.array().min(1, 'Select at least one keyword').required('Keywords are required'),
    jobLocations: yup.array().min(1, 'Select at least one job location').required('Job Locations are required'),
    clientId: yup.string('Select the client').required('Client is required'),
    education: yup.string('Select the education').required('Education is required'),
    degrees: yup.string('Enter the degrees').required('Degrees are required'),
    industry: yup.object({
        value: yup.string('Industry is required').required('Industry is required'),
        label: yup.string('Industry is required').required('Industry is required'),
    }).required('Industry is required'),
    functionalArea: yup.object({
        value: yup.string('Industry is required').required('Functional Area is required'),
        label: yup.string('Industry is required').required('Functional Area is required'),
    }).required('Functional Area is required')
    // industry: yup.array('Select the industry').min(1, 'Select the industry').required('Industry is required'),
    // functionalArea: yup.array('Select the functional area').min(1, 'Select the functional area').required('Functional Area is required')
});




export const AddRequirement = () => {
    const authUser = localStorage.getItem("rmsAuthUser");
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    if(user && user.role === 'Recuriter'){
        navigate('/dashboard')
    }
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("id")

    const [locations, setLocations ] = useState([]);
    const [clients, setClients ] = useState([]);
    const [functionalAreas, setFunctionalAreas ] = useState([]);
    const [industries, setIndustries ] = useState([]);

    let initialValues = {
        id: null,
        title: '',
        jobTitle: '',
        employmentType: '',
        jobDescription: '',
        salaryCurrency: 'Rs',
        minimumSalary: '',
        maximumSalary: '',
        minimumExperience: '',
        maximumExperience: '',
        keySkills: [],
        jobLocations: [],
        clientId: '',
        visibilitySetting: 'Visible to Only Me',
        noOfVacancies: '',
        referenceCode: '',
        education: '',
        degrees: '',
        industry: null,
        functionalArea: null,
        contactPerson: '',
        contactPhoneNumber: '',
        companyWebsite: '',
        companyName: '',
        aboutCompany: ''
    }

    useEffect(() => {
        axios.get(`requirement?id=${id}`).then(res => {
            console.log(res);
            if(res.data.success){
                if(res.data.data){
                    formik.setValues(res.data.data)
                }
                
            }
        })        
    },[id > 0]);  
    
    useEffect(() => {
        axios.get(`locationsForSelect`).then(res => {
            // console.log(res);
            if(res.data.success){
                setLocations(res.data.data)
            }
        })
        axios.get(`clients`).then(res => {
            // console.log(res);
            if(res.data.success){
                setClients(res.data.data)
            }
        })
        axios.get(`functionalAreasForSelect`).then(res => {
            // console.log(res);
            if(res.data.success){
                setFunctionalAreas(res.data.data)
            }
        })
        axios.get(`industriesForSelect`).then(res => {
            // console.log(res);
            if(res.data.success){
                setIndustries(res.data.data)
            }
        })
    }, [])

    const loadKeySkills = (value) => {
        return new Promise((resolve) => {
            axios.get(`keySkillsForSelect?name=${value}`).then(res => {
                // console.log(res);
                if(res.data.success){
                    resolve(res.data.data)
                }
            })
        })
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        }
    });

    const handleSubmit = (values) =>{
        axios.post(`requirements/create`, values).then(res => {
            if(res.data.success){
                Swal.fire(res.data.message, '', 'success').then(result => {
                    navigate('/requirements');
                })                
            } 
            else{
                Swal.fire(res.data.message, '', 'error')
            }
        }).catch((error) => {
            // console.log(error.response.data)
            Swal.fire(error.response.data.message, '', 'error')
        });
    }
    
    return (
        <AdminLayout>
            <div className="row mb-2">
                <div className="col-6">
                    <div className="pagetitle">
                        <h1>{id ? 'Edit' : 'Add'} Requirement</h1>
                    </div>
                </div>
            </div>

            <section className="section dashboard">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-xxl-12 col-md-8">
                                <div className="card recent-sales overflow-auto">
                                    <div className="card-body">

                                        <form className="g-3 mt-3" autoComplete="off" onSubmit={formik.handleSubmit}>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Requirement Title<span className="text-danger">*</span></label>
                                                        <input type="text" name="title" className="form-control" value={formik.values.title} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                        {formik.touched.title && formik.errors.title ? <div className="text-danger">{formik.errors.title}</div> : null} 
                                                    </div>
                                                </div>
                                                <div className='col-md-6'></div>
                                            </div>
                                            
                                            <h5>Requirement Details</h5>
                                            <p>Being posting your job with entering some basic information about this posting</p>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Job Title/Desgination<span className="text-danger">*</span></label>
                                                        <input type="text" name="jobTitle" className="form-control" value={formik.values.jobTitle} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                        {formik.touched.jobTitle && formik.errors.jobTitle ? <div className="text-danger">{formik.errors.jobTitle}</div> : null} 
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Employment Type<span className="text-danger">*</span></label>
                                                        <select name="employmentType" className="form-control" value={formik.values.employmentType} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                            <option>Select Employment Type</option>
                                                            <option value='Full Time, Permanent'>Full Time, Permanent</option>
                                                            <option value='Full Time, Temporary/Contractual'>Full Time, Temporary/Contractual</option>
                                                            <option value='Full Time, Freelance/Homebased'>Full Time, Freelance/Homebased</option>
                                                            <option value='Part Time, Permanent'>Part Time, Permanent</option>
                                                            <option value='Part Time, Temporary/Contractual'>Part Time, Temporary/Contractual</option>
                                                            <option value='Part Time, Freelance/Homebased'>Part Time, Freelance/Homebased</option>                                                    
                                                        </select>
                                                        {formik.touched.employmentType && formik.errors.employmentType ? <div className="text-danger">{formik.errors.employmentType}</div> : null} 
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Job Description<span className="text-danger">*</span></label>
                                                <CKEditor
                                                    editor={ ClassicEditor }
                                                    data={formik.values.jobDescription}
                                                    onReady={ editor => {
                                                        // You can store the "editor" and use when it is needed.
                                                        console.log( 'Editor is ready to use!', editor );
                                                    } }
                                                    onChange={ ( event, editor ) => {
                                                        const data = editor.getData();
                                                        formik.setFieldValue('jobDescription', data)
                                                        console.log( { event, editor, data } );
                                                    } }
                                                    onBlur={ ( event, editor ) => {
                                                        console.log( 'Blur.', editor );
                                                    } }
                                                    onFocus={ ( event, editor ) => {
                                                        console.log( 'Focus.', editor );
                                                    } }
                                                />
                                                {formik.touched.jobDescription && formik.errors.jobDescription ? <div className="text-danger">{formik.errors.jobDescription}</div> : null} 
                                            </div>
                                            <div className="mb-3">
                                                <div className='row'>
                                                    <div className='col-md-2'>
                                                        <label className="form-label">Annual CTC<span className="text-danger">*</span></label>
                                                        <select name="salaryCurrency" className="form-control" value={formik.values.salaryCurrency} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                            <option>Select Currency</option>
                                                            <option value='Rs'>Rs</option>
                                                            <option value='USD'>USD</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <label className="form-label">Minimum Salary<span className="text-danger">*</span></label>
                                                        <input type="text" name="minimumSalary" className="form-control" value={formik.values.minimumSalary} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                        {formik.touched.minimumSalary && formik.errors.minimumSalary ? <div className="text-danger">{formik.errors.minimumSalary}</div> : null}
                                                    </div>
                                                    <div className="col-md-5">
                                                        <label className="form-label">Maximum Salary<span className="text-danger">*</span></label>
                                                        <input type="text" name="maximumSalary" className="form-control" value={formik.values.maximumSalary} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                        {formik.touched.maximumSalary && formik.errors.maximumSalary ? <div className="text-danger">{formik.errors.maximumSalary}</div> : null}
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div className="mb-3">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label className="form-label">Work Experience Minimum (Years)<span className="text-danger">*</span></label>
                                                        <input type="text" name="minimumExperience" className="form-control" value={formik.values.minimumExperience} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                        {formik.touched.minimumExperience && formik.errors.minimumExperience ? <div className="text-danger">{formik.errors.minimumExperience}</div> : null}
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Work Experience Maximum (Years)<span className="text-danger">*</span></label>
                                                        <input type="text" name="maximumExperience" className="form-control" value={formik.values.maximumExperience} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                        {formik.touched.maximumExperience && formik.errors.maximumExperience ? <div className="text-danger">{formik.errors.maximumExperience}</div> : null}
                                                    </div>
                                                </div>                                                
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Keywords<span className="text-danger">*</span></label>
                                                {/* <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    placeholder="Select and type keywords"
                                                    value={formik.values.keywords}
                                                    // isDisabled={isDisabled}
                                                    // isLoading={isLoading}
                                                    isClearable={true}
                                                    // isRtl={isRtl}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                    name="keywords"
                                                    options={keySkills}
                                                    onChange={(value) => {value ? formik.setFieldValue('keywords', value) : formik.setFieldValue('keywords', [])}}
                                                /> */}
                                                <AsyncSelect
                                                    cacheOptions
                                                    defaultOptions
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    placeholder="Type and select keywords"
                                                    // isDisabled={isDisabled}
                                                    isLoading={false}
                                                    isClearable={true}
                                                    // isRtl={isRtl}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                    name="keySkills"
                                                    value={formik.values.keySkills}
                                                    loadOptions={(e)=>loadKeySkills(e)}
                                                    onChange={(value) => {value ? formik.setFieldValue('keySkills', value) : formik.setFieldValue('keySkills', [])}}
                                                />
                                                {formik.touched.keySkills && formik.errors.keySkills ? <div className="text-danger">{formik.errors.keySkills}</div> : null}
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Job Location<span className="text-danger">*</span></label>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    placeholder="Select and type location"
                                                    value={formik.values.jobLocations}
                                                    // isDisabled={isDisabled}
                                                    // isLoading={isLoading}
                                                    isClearable={true}
                                                    // isRtl={isRtl}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                    name="jobLocations"
                                                    options={locations}
                                                    onChange={(value) => {value ? formik.setFieldValue('jobLocations', value) : formik.setFieldValue('jobLocations', [])}}
                                                />
                                                {formik.touched.jobLocations && formik.errors.jobLocations ? <div className="text-danger">{formik.errors.jobLocations}</div> : null}
                                            </div>
                                            <h5 className="mt-2">Additional Details</h5>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Hiring For</label>
                                                        <select name="clientId" className="form-control" value={formik.values.clientId} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                            <option>No Employer Selected</option>
                                                            {
                                                                clients.map(client => {
                                                                    return (
                                                                        <option value={client.id}>{client.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        {formik.touched.clientId && formik.errors.clientId ? <div className="text-danger">{formik.errors.clientId}</div> : null}
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Visibility Setting</label>
                                                        <select name="visibilitySetting" className="form-control" value={formik.values.visibilitySetting} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                            <option value='Visible to Only Me'>Visible to Only Me</option>
                                                            <option value='Visible to All'>Visible to All</option>
                                                            {/* <option value='Visible to Selected People'>Visible to Selected People</option> */}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">No. Of Vacancies</label>
                                                        <input type="text" name="noOfVacancies" className="form-control" value={formik.values.noOfVacancies} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Reference Code</label>
                                                        <input type="text" name="referenceCode" className="form-control" value={formik.values.referenceCode} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                    </div>
                                                </div>
                                            </div>                                            
                                            <h5 className="mt-2">Educational qualifications</h5>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Education<span className="text-danger">*</span></label>
                                                        <select name="education" className="form-control" value={formik.values.education} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                            <option>Select Education</option>
                                                            <option value='Graduation Not Required'>Graduation Not Required</option>
                                                            <option value='Graduate'>Graduate</option>                                                            
                                                            <option value='Postgraduate'>Postgraduate</option>                                                            
                                                            <option value='Doctoral/Ph.d'>Doctoral/Ph.d</option>                                                  
                                                        </select>
                                                        {formik.touched.education && formik.errors.education ? <div className="text-danger">{formik.errors.education}</div> : null}
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Degrees<span className="text-danger">*</span></label>
                                                        <input type="text" name="degrees" className="form-control" value={formik.values.degrees} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                        {formik.touched.degrees && formik.errors.degrees ? <div className="text-danger">{formik.errors.degrees}</div> : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 className="mt-2 mb-2">Job Categorization</h5>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Industry<span className="text-danger">*</span></label>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            placeholder="Select and type industry"
                                                            value={formik.values.industry}
                                                            // isDisabled={isDisabled}
                                                            // isLoading={isLoading}
                                                            isClearable={true}
                                                            // isRtl={isRtl}
                                                            isSearchable={true}
                                                            name="industry"
                                                            options={industries}
                                                            onChange={(value) => {value ? formik.setFieldValue('industry', value) : formik.setFieldValue('industry', null)}}
                                                        />
                                                        {formik.touched.industry && formik.errors.industry ? <div className="text-danger">{formik.errors.industry}</div> : null}
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Functional Area<span className="text-danger">*</span></label>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            placeholder="Select and type functional area"
                                                            value={formik.values.functionalArea}
                                                            // isDisabled={isDisabled}
                                                            // isLoading={isLoading}
                                                            isClearable={true}
                                                            // isRtl={isRtl}
                                                            isSearchable={true}
                                                            name="functionalArea"
                                                            options={functionalAreas}
                                                            onChange={(value) => {value ? formik.setFieldValue('functionalArea', value) : formik.setFieldValue('functionalArea', null)}}
                                                        />
                                                        {formik.touched.functionalArea && formik.errors.functionalArea ? <div className="text-danger">{formik.errors.functionalArea}</div> : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 className="mt-2 mb-2">Contact Details</h5>
                                            <div className='row mb-3'>
                                                <div className='col-md-6'>
                                                    <label className="form-label">Contact Person</label>
                                                    <input type="text" name="contactPerson" className="form-control" value={formik.values.contactPerson} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label">Phone Number</label>
                                                    <input type="text" name="contactPhoneNumber" className="form-control" value={formik.values.contactPhoneNumber} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                </div>
                                            </div>
                                            <div className='row mb-3'>
                                                <div className='col-md-6'>
                                                    <label className="form-label">Company Website</label>
                                                    <input type="text" name="companyWebsite" className="form-control" value={formik.values.companyWebsite} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label">Company Name</label>
                                                    <input type="text" name="companyName" className="form-control" value={formik.values.companyName} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">About Company</label>
                                                <textarea className='form-control' name="aboutCompany" value={formik.values.aboutCompany} onChange={formik.handleChange} onBlur={formik.handleBlur}></textarea>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="text-end">
                                                    <button type='submit' className="ta-btn ta-btn-primary text-center"> <i className="bi bi-save mr-2"></i> <span> Save</span> </button>
                                                </div>
                                            </div>                                      
                                        </form>
                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </AdminLayout>
    );
}