import './Dashboard.css';
import React, { useEffect, useState } from 'react';
import { AdminLayout } from '../Layout/AdminLayout';
import ReactApexChart from 'react-apexcharts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faFile, faFileCircleXmark, faTrophy, faUsers } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const Dashboard = () => {
    const authUser = localStorage.getItem("rmsAuthUser");
    const auser = JSON.parse(authUser);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [isChartLoading, setIsChartLoading] = useState(true)
    const [allProfilesCount, setAllProfilesCount] = useState(0)
    const [profilesWithResumeCount, setProfilesWithResumeCount] = useState(0)
    const [profilesWithoutResumeCount, setProfilesWithoutResumeCount] = useState(0)
    const [totalRequirements, setTotalRequirements] = useState(0)
    const [statuses, setStatuses] = useState([])
    const [statusNumbers, setStatusNumbers] = useState([])
    const [users, setUsers] = useState([])
    const [recuriterId, setRecuriterId] = useState(0)
    const [dateRange, setDateRange] = useState('NoRange')

    useEffect(() => {
        getDashboardNumbers();
        getUsers()
      }, []);

    const getDashboardNumbers = async() => {
        await axios.get('/getDashboardNumbers')
        .then(function (response) {
            setLoading(false)
            let result = response.data;
            if(result.success){
                setAllProfilesCount(result.data.allProfiles)
                setProfilesWithResumeCount(result.data.profilesWithResume)
                setProfilesWithoutResumeCount(result.data.profilesWithoutResume)
                setTotalRequirements(result.data.totalRequirements)
                setIsChartLoading(false)
            }
        })
    }

    const getReports = async() => {
        await axios.get('/getReports?dateRange='+dateRange+'&recuriterId='+recuriterId)
        .then(function (response) {            
            let result = response.data;
            if(result.success){
                setStatuses(result.data.statuses)
                setStatusNumbers(result.data.statusNumbers)
            }
        })
    }

    const getUsers = async() => {
        await axios.get('/users?order=ASC')
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setUsers(result.data)
            }
        })
    }
    
    let options = {
        chart: {
            id: 'apexchart-example',
            events: {
                click: (event, chartContext, config) => {
                    console.log(config.dataPointIndex);
                    console.log("statuses",statuses[config.dataPointIndex]);
                    navigate('/candidate_profiles?status='+statuses[config.dataPointIndex])
                }
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 0,
                horizontal: true,
            }
        },
        xaxis: {
          categories: statuses
        },
        fill: {
            colors: ['#083B66']
        },
        tooltip: {
            // followCursor: true,
            // custom: function({series, seriesIndex, dataPointIndex, w}) {
            //     // console.log("statuses",statuses[dataPointIndex]);
            //   return '<div class="arrow_box">' +
            //     '<span>' + series[seriesIndex][dataPointIndex] + '</span>' +
            //     '</div>'
            // }
        }
    }

    let series = [{
        name: 'Total',
        data: statusNumbers
    }]

    useEffect(() => {
        getReports()
      }, [dateRange, recuriterId]);

    const handleDateRange = (e) => {
        setDateRange(e.target.value)
    }

    const handleRecuriter = (e) => {
        setRecuriterId(e.target.value)
    }

    return (
        <AdminLayout>
            
            <div className="row d-flex align-items-center mb-3">
                <div className="col-lg-6 col-md-8 col-sm-12">
                    <div className="pagetitle">
                        <h1>Dashboard</h1>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 ">
                        
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12">
                    
                    </div>
                </div>
            </div>
            {
                loading === false ?
                <div className="row d-flex align-items-center justify-content-center">
                    <div className='col-md-3'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='dashboard-top-card d-flex align-items-center justify-content-between'>
                                    <div>
                                        <label className='small'>Total Profiles</label>
                                        <h3 className='fw-semibold blue'>{allProfilesCount}</h3>
                                    </div>
                                    <div className='dashboard-top-card-icon blue'>
                                        <FontAwesomeIcon icon={faUsers} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='dashboard-top-card d-flex align-items-center justify-content-between'>
                                    <div>
                                        <label className='small'>Total Profiles with Resume</label>
                                        <h3 className='fw-semibold green'>{profilesWithResumeCount}</h3>
                                    </div>
                                    <div className='dashboard-top-card-icon green'>
                                        <FontAwesomeIcon icon={faFile} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='dashboard-top-card d-flex align-items-center justify-content-between'>
                                    <div>
                                        <label className='small'>Total Profiles without Resume</label>
                                        <h3 className='fw-semibold yellow'>{profilesWithoutResumeCount}</h3>
                                    </div>
                                    <div className='dashboard-top-card-icon yellow'>
                                        <FontAwesomeIcon icon={faFileCircleXmark}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='dashboard-top-card d-flex align-items-center justify-content-between'>
                                    <div>
                                        <label className='small'>Total Requirements</label>
                                        <h3 className='fw-semibold'>{totalRequirements}</h3>
                                    </div>
                                    <div className='dashboard-top-card-icon'>
                                        <FontAwesomeIcon icon={faBriefcase} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : ''
            }
            {
                auser.role === 'Admin' ?
                isChartLoading === false ?
                <>                
                <div className='selection-status-report my-5'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <h5>Selection Status Report</h5>
                        <div className='d-flex align-items-center ms-auto'>
                            <select className='form-control dropdown-arrow dropdown-width' value={dateRange} onChange={handleDateRange}>
                                <option value="NoRange">Select Range</option>
                                <option value="7">Last 7 Days</option>
                                <option value="15">Last 15 Days</option>
                                <option value="Current">Current Month</option>
                                <option value="Previous">Previous Month</option>
                                <option value="Custom">Custom Date Range</option>                                
                            </select>
                            <select className='form-control dropdown-arrow dropdown-width ms-2' value={recuriterId} onChange={handleRecuriter}>
                                <option value="0">All Recuriter</option>
                                {
                                    users.map(user => {
                                        return (
                                            <option value={user.id}>{user.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        
                    </div>
                    
                    <ReactApexChart options={options} series={series} type="bar" height={450} />
                </div>
                </>
                : '' : ''
            }
            
        </AdminLayout>
    )

}