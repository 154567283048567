import React, { useEffect, useState } from 'react';
import { AdminLayout } from '../Layout/AdminLayout';
import { Table } from '../Common/Table';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Add } from './Add';
import { useNavigate } from 'react-router-dom';
import Switcher from 'react-switcher-rc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'

export const UserList = () => {
    const authUser = localStorage.getItem("rmsAuthUser");
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    if(user && user.role !== 'Admin'){
        navigate('/dashboard')
    }
    const [show, setShow] = useState(false);
    const [popupType, setPopupType] = useState('Add');    
    const [editId, setEditId] = useState(0);   
    const [users, setUsers] = useState([]);
    

    useEffect(() => {
        getList();
      }, []);

    const getList = async() => {
        await axios.get('/users?role=Manager,Recruiter')
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setUsers(result.data)
            }
        })
    }

    const handleAdd = () => {
        setEditId(0);
        setPopupType('Add');
        setShow(true);
    }

    const handleEdit = (id) => {
        setEditId(id);
        setPopupType('Edit');
        setShow(true);
    }

    const handleClose = () => setShow(false);

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) { 
                axios.delete(`/users/delete?id=${id}`).then((res) =>{
                    if(res.data.success){                             
                        Swal.fire('Deleted!', '', 'success')           
                        getList()
                    } 
                }).catch((error) => {
                    console.log(error);                     
                    Swal.fire(error.message, '', 'info')           
                });
            } else if (result.isDenied) {
              Swal.fire('Cancelled!', '', 'info')
            }
        }) 
    }

    const handleStatus = (e, id) => {       
        var status = 'Active';
        if(e.target.checked === true) {
            status = 'Active'
        }
        else {
            status = 'Inactive'
        }
        axios.put(`/users/updateStatus?id=${id}`, {status}).then(res => {
            if(res.data.success){
                getList()
                Swal.fire('Updated!', '', 'success')
            }
            else{
                Swal.fire('Try Again', '', 'error')
            }
        })
        .catch(err => {
        })
    }

    const columns = [
        {
            name: 'Name',
            selector: row => (
                <p>{row.name}</p>
            ),
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => (
                <p>{row.email}</p>
            ),
            sortable: true,
        },
        {
            name: 'Phone Number',
            selector: row => (
                <p>{row.phone}</p>
            ),
            sortable: true,
        },
        {
            name: 'Role',
            selector: row => (
                <p>{row.role}</p>
            ),
            sortable: true,
        },
        {
            name: 'Status',
            cell: row => (
                <Switcher 
                    name="my-switcher"
                    onChange={(e) => handleStatus(e, row.id)}
                    checked={row.status === 'Active' ? true : false}
                />
            ),
        },
        {
            name: 'Action',
            button: true,
            cell: row => (
                <div>
                    <span className="icon icon-sm icon-primary cursor-pointer" onClick={() => handleEdit(row.id)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </span>
                    <span className="icon icon-sm icon-danger cursor-pointer"  onClick={() => handleDelete(row.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </span>
                </div>
            ),
        }
    ];

    return (
        <AdminLayout>
            <div class="row d-flex align-items-center mb-2">
                <div class="col-8">
                    <div class="pagetitle">
                        <h1>Users</h1>
                    </div>
                </div>
                <div class="col-4 text-right">
                    <button onClick={()=>handleAdd()} class="ta-btn ta-btn-primary">
                        <FontAwesomeIcon icon={faPlus} /> <span>Add User</span>
                    </button>
                </div>
            </div>

            <div class="card recent-sales overflow-auto">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="content px-0">
                                <Table  columns={columns} data={users}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Add type={popupType} show={show} setShow={setShow} handleClose={handleClose} getList={getList} editId={editId} setEditId={setEditId}/>
        </AdminLayout>
    )

}