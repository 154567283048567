import React, { useEffect, useState } from 'react';
import { AdminLayout } from '../Layout/AdminLayout';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faCalendarAlt, faClock, faCircleMinus, faCirclePlus, faComment, faDownload, faEnvelope, faFilter, faMapMarkerAlt, faMobileAlt, faPenToSquare, faPlus, faUpload, faWallet, faAngleDoubleLeft, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { Info } from './Info';
import { Oval } from 'react-loader-spinner';
import { Upload } from './Upload';
import { UploadCsv } from './UploadCsv';
import { AddComment } from './AddComment';
import { ExportToExcel } from '../Common/ExportToExcel';
import MultiSelectTextInput from '../Common/MultiSelectTextInput';
import DumpResumeToS3 from './DumpResumeToS3';
import { Communication } from './Communication';
import moment from 'moment';

const generateExpOptions = (min, max) => {
    let options = []
    for(let i = min; max >= i; i++){
        options.push(<option key={i}>{i}</option>)
    }
    return options
}

export const JobSeekerList = () => {
    const authUser = localStorage.getItem("rmsAuthUser");
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    // if(user && user.role !== 'Admin'){
    //     navigate('/dashboard')
    // }
    const [searchParams, setSearchParams] = useSearchParams()
    const [isShowActions, setIsShowActions] = useState(false);
    const [checkedProfiles, setCheckedProfiles] = useState([]);
    const [isJobSeekerLoading, setIsJobSeekerLoading] = useState(true);    
    const [isJobSeekerUploading, setIsJobSeekerUploading] = useState(false);    
    const [showInfo, setShowInfo] = useState(false);
    const [infoId, setInfoId] = useState(0);
    const [jobSeekers, setJobSeekers] = useState([]);
    const [selectionProcessList, setSelectionProcessList] = useState([]);
    const [requirements, setRequirements] = useState([]);
    const [locations, setLocations] = useState([]);
    // const [designation, setDesignation] = useState([]);
    // const [clients, setClients] = useState([]);
    // const [keySkills, setKeySkills] = useState([]);
    const [functionalAreas, setFunctionalAreas ] = useState([]);
    const [industries, setIndustries ] = useState([]);
    const [minExpOptions, setMinExpOptions ] = useState(generateExpOptions(0, 30));
    const [maxExpOptions, setMaxExpOptions ] = useState(generateExpOptions(0, 30));
    const [minSalaryOptions, setMinSalaryOptions ] = useState(generateExpOptions(0, 50));
    const [maxSalaryOptions, setMaxSalaryOptions ] = useState(generateExpOptions(0, 50));

    const [checkedSelectionProcesses, setCheckedSelectionProcesses] = useState([]);
    const [limit, setLimit] = useState(30);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [profilesFilter, setProfilesFilter ] = useState('all');
    const [fliterKeywords, setFilterKeywords ] = useState([]);
    const [filterSearchInResume, setFilterSearchInResume ] = useState(false);
    const [fliterCurrentLocations, setFilterCurrentLocations ] = useState([]);
    const [fliterPreferredLocations, setFilterPreferredLocations ] = useState([]);
    const [filterMinExpYear, setFilterMinExpYear ] = useState(null);
    const [filterMaxExpYear, setFilterMaxExpYear ] = useState(null);
    const [filterMinSalary, setFilterMinSalary ] = useState(null);
    const [filterMaxSalary, setFilterMaxSalary ] = useState(null);
    const [filterCurrDesignations, setFilterCurrDesignations ] = useState([]);
    const [filterCurrCompany, setFilterCurrCompany ] = useState([]);
    const [filterDepartments, setFilterDepartments ] = useState([]);
    const [filterIndustries, setFilterIndustries ] = useState([]);
    const [filterLatestDays, setFilterLatestDays ] = useState([]);
    const [isShowSPFilter, setIsShowSPFilter] = useState(true)
    const [isShowOtherFilter, setIsShowOtherFilter] = useState(true)

    const [showAddComment, setShowAddComment] = useState(false);
    const [addCommentId, setAddCommentId] = useState(0);

    const handleAddComment = (id) => {
        setAddCommentId(id)
        setShowAddComment(true);
    }

    useEffect(() => {        
        getSelectionProcessList();
        getRequirements()        
        axios.get('locationsForSelect').then(res => {
            // console.log(res);
            if(res.data.success){
                setLocations(res.data.data)
            }
        })
        
        axios.get(`functionalAreasForSelect`).then(res => {
            // console.log(res);
            if(res.data.success){
                setFunctionalAreas(res.data.data)
            }
        })
        axios.get(`industriesForSelect`).then(res => {
            // console.log(res);
            if(res.data.success){
                setIndustries(res.data.data)
            }
        })
    }, []);
    
    useEffect(() => {
        getList()
    }, [setSearchParams, limit, page, checkedSelectionProcesses, profilesFilter, fliterKeywords, filterSearchInResume, fliterCurrentLocations, fliterPreferredLocations, filterMinExpYear, filterMaxExpYear, filterMinSalary, filterMaxSalary, filterCurrDesignations, filterCurrCompany, filterDepartments, filterIndustries, filterLatestDays])

    const getList = async () => {
        setIsJobSeekerLoading(true)
        let tempFilterKeywords = []
        let tempFilterCompanies = []
        let tempFilterDesginations = []
        
        for(let i = 0; fliterKeywords.length > i; i++){
            tempFilterKeywords.push(fliterKeywords[i].label)
        }
        for(let i = 0; filterCurrCompany.length > i; i++){
            tempFilterCompanies.push(filterCurrCompany[i].label)
        }
        for(let i = 0; filterCurrDesignations.length > i; i++){
            tempFilterDesginations.push(filterCurrDesignations[i].label)
        }
        await axios.get('/job_seekers?limit='+limit+'&page='+page+'&selectionStatus='+searchParams.get('status')+'&requirementId='+searchParams.get('requirementId')+'&selectionProcesses='+checkedSelectionProcesses+'&profiles='+profilesFilter+'&keywords='+tempFilterKeywords+'&searchInResume='+filterSearchInResume+'&currentLocations='+fliterCurrentLocations+'&preferredLocations='+fliterPreferredLocations+'&minExpYear='+filterMinExpYear+'&maxExpYear='+filterMaxExpYear+'&minSalary='+filterMinSalary+'&maxSalary='+filterMaxSalary+'&currDesignations='+tempFilterDesginations+'&currCompany='+tempFilterCompanies+'&departments='+filterDepartments+'&industries='+filterIndustries+'&latestDays='+filterLatestDays)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setJobSeekers(result.data)
                setTotal(result.total)
                setPage(result.page)
                setTotalPages(result.totalPages)
                setIsJobSeekerLoading(false)
            }
            else {
                // setIsError(true);
            }
        })
    }

    const getSelectionProcessList = async() => {
        await axios.get('/selection_process_list')
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setSelectionProcessList(result.data)
            }
            else {
                // setIsError(true);
            }
        })
    }

    const getRequirements = async() => {
        await axios.get('/requirements')
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setRequirements(result.data)
                // getList();
            }
            else {
                // setIsError(true);
            }
        })
    }

    // const getClients = async() => {
    //     await axios.get('/clients')
    //     .then(function (response) {
    //         let result = response.data;
    //         if(result.success){
    //             setClients(result.data)
    //         }
    //         else {
    //             // setIsError(true);
    //         }
    //     })
    // }

    


    const handleDelete = () => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) { 
                axios.delete(`/job_seekers/delete?ids=${checkedProfiles}`).then((res) =>{
                    if(res.data.success){                             
                        Swal.fire('Deleted!', '', 'success')
                        window.location.reload()
                    } 
                }).catch((error) => {
                    console.log(error);                     
                    Swal.fire(error.message, '', 'info')           
                });
            } else if (result.isDenied) {
              Swal.fire('Cancelled!', '', 'info')
            }
        }) 
    }

    const handleMarkFavourite = () => {
        axios.post(`/job_seekers/markFavourite?ids=${checkedProfiles}`).then((res) =>{
            if(res.data.success){                             
                Swal.fire('Marked as Favourite', '', 'success')
                setCheckedProfiles([])
                navigate('/candidate_profiles');
            } 
        }).catch((error) => {
            console.log(error);                     
            Swal.fire(error.message, '', 'info')           
        });
    }

    // const handleKeywordsChange = (keywords) => {
    //     let tempArr = []
    //     for(let i = 0; keywords.length > i; i++){
    //         tempArr.push(keywords[i].label)
    //     }
    //     setFilterKeywords(tempArr)
    // }

    const handleCurrentLocationsChange = (currentLocations) => {
        let tempArr = []
        for(let i = 0; currentLocations.length > i; i++){
            tempArr.push(currentLocations[i].value)
        }
        setFilterCurrentLocations(tempArr)
    }

    // const handleCurrCompanyChange = (currentCompany) => {
    //     let tempArr = []
    //     for(let i = 0; currentCompany.length > i; i++){
    //         tempArr.push(currentCompany[i].value)
    //     }
    //     setFilterCurrCompany(tempArr)
    // }

    // const handleCurrDesignationsChange = (currentDesignations) => {
    //     let tempArr = []
    //     for(let i = 0; currentDesignations.length > i; i++){
    //         tempArr.push(currentDesignations[i].value)
    //     }
    //     setFilterCurrDesignations(tempArr)
    // }

    const handlePreferredLocationsChange = (preferredLocations) => {
        let tempArr = []
        for(let i = 0; preferredLocations.length > i; i++){
            tempArr.push(preferredLocations[i].value)
        }
        setFilterPreferredLocations(tempArr)
    }

    const handleMinExpChange = (e) => {
        let minValue = e.currentTarget.value
        setFilterMinExpYear(minValue)
        setMaxExpOptions(generateExpOptions(minValue, 30))
    }

    const handleMaxExpChange = (e) => {
        let maxValue = e.currentTarget.value
        setFilterMaxExpYear(maxValue)
        setMinExpOptions(generateExpOptions(0, maxValue))
    }

    const handleMinSalaryChange = (e) => {
        let minValue = e.currentTarget.value
        setFilterMinSalary(minValue)
        setMaxSalaryOptions(generateExpOptions(minValue, 50))
    }

    const handleMaxSalaryChange = (e) => {
        let maxValue = e.currentTarget.value
        setFilterMaxSalary(maxValue)
        setMinSalaryOptions(generateExpOptions(0, maxValue))
    }

    // const handleNEPChange = (value) => {
    //     if(value.length > 2)
    //         setFilterNEP(value)
    //     else
    //         setFilterNEP('')
    // }

    const formatExperience = (year, month) => {
        let exp = '';
        if(year === 0 && month === 0){
            exp = 'Fresher'
        }
        else if(year === 0 && month > 0){
            if(month > 1){
                exp = month+' Months'
            }
            else{
                exp = month+' Month'
            }
        }
        else if(year > 0 && month === 0){
            if(year > 1){
                exp = year+' Years'
            }
            else{
                exp = year+' Year'
            }
        }
        else if(year > 0 && month > 0){
            let ys = ' '
            let ms = ' '
            if(year > 1){
                ys = 's '
            }
            if(month > 1){
                ms = 's '
            }
            exp = year +' Year' + ys + month + ' Month' + ms
        }

        return exp
    }

    const handleDepartmentChange = (departments) => {
        let tempArr = []
        for(let i = 0; departments.length > i; i++){
            tempArr.push(departments[i].value)
        }
        setFilterDepartments(tempArr)
    }

    const handleIndustryChange = (industry) => {
        let tempArr = []
        for(let i = 0; industry.length > i; i++){
            tempArr.push(industry[i].value)
        }
        setFilterIndustries(industry)
    }

    const handleLatestApplicationsChange = (days) => {
        if(days === null){
            setFilterLatestDays([])
        }
        else{
            setFilterLatestDays(days.value)
        }
        
    }

    const showSelectionProcessInfo = (id) => {
        setInfoId(id)
        setShowInfo(true)
    }

    const handlePrev = () => {
        setPage(page-1)
    }

    const handleNext = () => {
        setPage(page+1)
    }

    const handleChangeSelectionProcess = (event) => {
        const { value, checked } = event.target;
        // console.log(checked)
        if (checked) {
            setCheckedSelectionProcesses([...checkedSelectionProcesses, value]);
        } else {
            setCheckedSelectionProcesses(checkedSelectionProcesses.filter((item) => item !== value));
        }
    };

    
    useEffect(() => {
        // console.log("checkedProfiles1111",checkedProfiles)
        if(checkedProfiles.length > 0){
            setIsShowActions(true)
        }
        else{
            setIsShowActions(false)
        }
    },[checkedProfiles])

    const handleCheckProfile = (e) => {
        console.log("checked",e.target.checked)
        console.log("checkedProfiles",checkedProfiles)
        if(e.target.checked){
            setCheckedProfiles([...checkedProfiles, parseInt(e.target.value)])
        }
        else{
            setCheckedProfiles(checkedProfiles.filter((item) => item !== parseInt(e.target.value)));
        }
    }

    
    const handleCheckAllProfile = e => {
        if(e.target.checked){
            setCheckedProfiles(jobSeekers.map(j => j.id));
        }
        else{
            setCheckedProfiles([]);
        }
      };

    const handleSearchInResumeCheck = (e) => {
        if(e.target.checked){
            setFilterSearchInResume(true)
        }
        else{
            setFilterSearchInResume(false);
        }
    }

    const handleProfilesFilter = (e) => {
        setProfilesFilter(e.target.value)
    }

    return (
        <AdminLayout>
            <div className="row d-flex align-items-center mb-2">
                <div className="col-6">
                    <div className="pagetitle">
                        <h1>
                            {searchParams.get('requirementId') > 0 && searchParams.get('title') !== '' ? 'Applied Candidates - '+searchParams.get('title') : 'Candidate Profiles'}
                        </h1>
                    </div>
                </div>
                <div className="col-6 text-right">
                    <div className='justify-content-end'>
                        <div className={isJobSeekerUploading ? 'd-none' : 'btn-group'}>
                            <button type="button" className="ta-btn ta-btn-reverse dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <FontAwesomeIcon icon={faUpload} className='pointor'/> Upload File
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li><button className="dropdown-item" type="button"><Upload getList={getList} setIsJobSeekerUploading={setIsJobSeekerUploading}/></button></li>
                                <li><button className="dropdown-item" type="button"><UploadCsv getList={getList} setIsJobSeekerUploading={setIsJobSeekerUploading}/></button></li>
                                <li><button className="dropdown-item" type="button"><DumpResumeToS3 getList={getList} setIsJobSeekerUploading={setIsJobSeekerUploading}/></button></li>
                            </ul>
                        </div>
                        <Oval
                            height={50}
                            width={50}
                            color="#0d3f67"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={isJobSeekerUploading}
                            ariaLabel='oval-loading'
                            secondaryColor="#0d3f67"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />
                        <Link to='/add_job_seeker' className="ta-btn ta-btn-primary ms-3">
                            <FontAwesomeIcon icon={faPlus} /> <span>Add Profile</span>
                        </Link>
                    </div>
                    
                    
                </div>
            </div>
            <section>
                <div className="mt-4">
                    <div className="row">
                        <div className="col-md-3">                            
                            <div className="card">
                                <div className="card-header d-flex">
                                    <span><FontAwesomeIcon icon={faFilter} /> Filters</span>
                                    <span className='ms-auto'>
                                        <FontAwesomeIcon icon={faCirclePlus} className={isShowOtherFilter ? 'd-none' : 'pointor'} onClick={() => setIsShowOtherFilter(isShowOtherFilter ? false : true)}/>    
                                        <FontAwesomeIcon icon={faCircleMinus} className={isShowOtherFilter ? 'pointor' : 'd-none'} onClick={() => setIsShowOtherFilter(isShowOtherFilter ? false : true)}/>    
                                    </span>
                                </div>
                                <div>
                                    <ul className={isShowOtherFilter ? "list-group list-group-flush" : "d-none"}>
                                        <li className="list-group-item">
                                            <label className="form-label d-block">Profiles</label>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" name="profilesFilter" type="radio" value="all" id="allProfiles" onChange={handleProfilesFilter} checked={profilesFilter === 'all' ? true : false}/>
                                                <label className="form-check-label" for="allProfiles">
                                                    All
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" name="profilesFilter" type="radio" value="withResume" id="withResume" onChange={handleProfilesFilter} checked={profilesFilter === 'withResume' ? true : false}/>
                                                <label className="form-check-label" for="withResume">
                                                    With Resume
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" name="profilesFilter" type="radio" value="withoutResume" id="withoutResume" onChange={handleProfilesFilter} checked={profilesFilter === 'withoutResume' ? true : false}/>
                                                <label className="form-check-label" for="withoutResume">
                                                    Without Resume
                                                </label>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <label className="form-label">Keywords</label>
                                            <MultiSelectTextInput placeholder="Type something and press enter..." inputs={fliterKeywords} setInputs={setFilterKeywords}/>
                                            <div className="form-check mt-1">
                                                <input className="form-check-input" type="checkbox" value="" id="searchInResumeCheckChecked" onChange={handleSearchInResumeCheck} checked={filterSearchInResume}/>
                                                <label className="form-check-label" for="searchInResumeCheckChecked">
                                                    Search in Resume
                                                </label>
                                            </div>
                                            {/* <AsyncCreatableSelect
                                                cacheOptions
                                                defaultOptions
                                                isClearable={true}
                                                isMulti={true}
                                                // loadOptions={(e)=>loadKeywords(e)}
                                                onChange={(value) => {handleKeywordsChange(value)}}
                                            /> */}
                                        </li>
                                        
                                        {/* <li className="list-group-item">
                                            <label className="form-label">Name/Email/Phone</label>
                                            <input type='text' className='form-control' placeholder='Enter the name/email/phone' onChange={(e)=>handleNEPChange(e.currentTarget.value)}/>
                                        </li>
                                        <li className="list-group-item">
                                            <label className="form-label">Key Skills</label>
                                            <AsyncSelect
                                                cacheOptions
                                                defaultOptions
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Type and select key skills"
                                                // isDisabled={isDisabled}
                                                // isLoading={isLoading}
                                                isClearable={true}
                                                // isRtl={isRtl}
                                                isMulti={true}
                                                isSearchable={true}
                                                name="keySkills"
                                                loadOptions={(e)=>loadKeySkills(e)}
                                                onChange={(value) => {handleKeySkillsChange(value)}}
                                            />
                                        </li> */}
                                        <li className="list-group-item">
                                            <label className="form-label">Current Location</label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Select and type location"
                                                // isDisabled={isDisabled}
                                                // isLoading={isLoading}
                                                isClearable={true}
                                                // isRtl={isRtl}
                                                isMulti={true}
                                                isSearchable={true}
                                                name="currentLocation"
                                                options={locations}
                                                onChange={(value) => {handleCurrentLocationsChange(value)}}
                                            />
                                        </li>
                                        <li className="list-group-item">
                                            <label className="form-label">Preferred Locations</label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Select and type location"
                                                // isDisabled={isDisabled}
                                                // isLoading={isLoading}
                                                isClearable={true}
                                                // isRtl={isRtl}
                                                isMulti={true}
                                                isSearchable={true}
                                                name="preferredLocations"
                                                options={locations}
                                                onChange={(value) => {handlePreferredLocationsChange(value)}}
                                            />
                                        </li>
                                        <li className="list-group-item">
                                            <label className="form-label">Experience (Years)</label>
                                            <div className='row'>
                                                <div className='col-md-5'>
                                                    <select name="minExpYear" className="form-control mb-3" onChange={(e)=>handleMinExpChange(e)}>
                                                        <option>Min</option>                                                            
                                                        {minExpOptions}
                                                    </select>
                                                </div>
                                                <div className='col-md-2'style={{paddingTop: "5px"}}>
                                                    <span>to</span>
                                                </div>
                                                <div className='col-md-5'>
                                                    <select name="maxExpYear" className="form-control" onChange={(e)=>handleMaxExpChange(e)}>                                                
                                                        <option>Max</option>      
                                                        {maxExpOptions}
                                                    </select>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <label className="form-label">Salary (INR-Lakhs)</label>
                                            <div className='row'>
                                                <div className='col-md-5'>
                                                    <select name="minSalary" className="form-control mb-3" onChange={(e)=>handleMinSalaryChange(e)}>
                                                        <option>Min</option>                                                            
                                                        {minSalaryOptions}
                                                    </select>
                                                </div>
                                                <div className='col-md-2'style={{paddingTop: "5px"}}>
                                                    <span>to</span>
                                                </div>
                                                <div className='col-md-5'>
                                                    <select name="maxSalary" className="form-control" onChange={(e)=>handleMaxSalaryChange(e)}>                                                
                                                        <option>Max</option>      
                                                        {maxSalaryOptions}
                                                    </select>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <label className="form-label">Current Company</label>
                                            <MultiSelectTextInput placeholder="Type company and press enter..." inputs={filterCurrCompany} setInputs={setFilterCurrCompany}/>
                                            {/* <AsyncSelect
                                                cacheOptions
                                                defaultOptions
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Type company"
                                                // isDisabled={isDisabled}
                                                // isLoading={isLoading}
                                                isClearable={true}
                                                // isRtl={isRtl}
                                                isMulti={true}
                                                isSearchable={true}
                                                name="currDesignations"
                                                loadOptions={(e)=>loadCurrCompany(e)}
                                                onChange={(value) => {handleCurrCompanyChange(value)}}
                                            /> */}
                                        </li>
                                        <li className="list-group-item">
                                            <label className="form-label">Current Designation</label>
                                            <MultiSelectTextInput placeholder="Type designation and press enter..." inputs={filterCurrDesignations} setInputs={setFilterCurrDesignations}/>
                                            {/* <AsyncSelect
                                                cacheOptions
                                                defaultOptions
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Type designations"
                                                // isDisabled={isDisabled}
                                                // isLoading={isLoading}
                                                isClearable={true}
                                                // isRtl={isRtl}
                                                isMulti={true}
                                                isSearchable={true}
                                                name="currDesignations"
                                                loadOptions={(e)=>loadCurrDesignations(e)}
                                                onChange={(value) => {handleCurrDesignationsChange(value)}}
                                            /> */}
                                        </li>
                                        <li className="list-group-item">
                                            <label className="form-label">Department</label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Select Departments"
                                                // isDisabled={isDisabled}
                                                // isLoading={isLoading}
                                                isClearable={true}
                                                // isRtl={isRtl}
                                                isMulti={true}
                                                isSearchable={true}
                                                name="departments"
                                                options={functionalAreas}
                                                onChange={(value) => {handleDepartmentChange(value)}}
                                            />
                                        </li>
                                        <li className="list-group-item">
                                            <label className="form-label">Industry</label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Select Industries"
                                                // isDisabled={isDisabled}
                                                // isLoading={isLoading}
                                                isClearable={true}
                                                // isRtl={isRtl}
                                                isMulti={true}
                                                isSearchable={true}
                                                name="industries"
                                                options={industries}
                                                onChange={(value) => {handleIndustryChange(value)}}
                                            />
                                        </li>
                                        <li className="list-group-item">
                                            <label className="form-label">Latest Applications by Days</label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Select Days"
                                                // isDisabled={isDisabled}
                                                // isLoading={isLoading}
                                                isClearable={true}
                                                // isRtl={isRtl}
                                                isMulti={false}
                                                isSearchable={true}
                                                name="latestApplications"
                                                options={[{value: 7, label: '7 Days'},{value: 15, label: '15 Days'},{value: 30, label: '30 Days'}]}
                                                onChange={(value) => {handleLatestApplicationsChange(value)}}
                                            />
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header d-flex">
                                    <span>Selection Process Filters</span>
                                    <span className='ms-auto'>
                                        <FontAwesomeIcon icon={faCirclePlus} className={isShowSPFilter ? 'd-none' : 'pointor'} onClick={() => setIsShowSPFilter(isShowSPFilter ? false : true)}/>    
                                        <FontAwesomeIcon icon={faCircleMinus} className={isShowSPFilter ? 'pointor' : 'd-none'} onClick={() => setIsShowSPFilter(isShowSPFilter ? false : true)}/>    
                                    </span>
                                </div>
                                <ul className={isShowSPFilter ? "list-group list-group-flush" : "d-none"}>
                                {/* <ul className="list-group list-group-flush "> */}
                                    {
                                        selectionProcessList.map(selectionProcess => {
                                            return(
                                            <li className="list-group-item">
                                        
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value={selectionProcess.id} id={"chb"+selectionProcess.id}  onChange={handleChangeSelectionProcess}/>
                                                    <label className="form-check-label" htmlFor={"chb"+selectionProcess.id}>
                                                        {selectionProcess.name}
                                                    </label>
                                                </div>
                                            </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className={isJobSeekerLoading ? 'd-none' : ''}>
                                <div className="ta-js-pagination">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="d-flex align-items-center">
                                                <span className='total-heading'>Total: {total}</span>                                            
                                            </div>
                                            {
                                                isShowActions === true ? 
                                                <div className="form-check mt-1">
                                                    <input className="form-check-input" type="checkbox" id="checkAll" onChange={handleCheckAllProfile} checked={checkedProfiles.length === jobSeekers.length}/>
                                                    <label className="form-check-label" htmlFor="checkAll">
                                                        Check All Profile
                                                    </label>
                                                </div>
                                                : ''
                                            }
                                        </div>
                                        <div className="col-md-4">
                                            {
                                                isShowActions === true ? 
                                                <div className="d-flex align-items-center">
                                                    <div className="me-2">                                                    
                                                        <div className="btn-group">
                                                            <button type="button" className="ta-btn ta-btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                                Actions
                                                            </button>
                                                            <ul className="dropdown-menu dropdown-menu-end">
                                                                {
                                                                    user.role === 'Admin' ?
                                                                    <>
                                                                    <li><button className="dropdown-item" type="button" onClick={handleDelete}>Delete Profile(s)</button></li>
                                                                    <li><ExportToExcel checkedProfiles={checkedProfiles} /></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={handleMarkFavourite}>Mark as favourite Profile(s)</button></li>
                                                                    </>
                                                                    : <li><button className="dropdown-item" type="button" onClick={handleMarkFavourite}>Mark as favourite Profile(s)</button></li>
                                                                }
                                                                
                                                                
                                                            </ul>
                                                        </div>                                   
                                                    </div>
                                                    <div className="">                                                    
                                                        <Communication checkedProfiles={checkedProfiles}/>                                   
                                                    </div>
                                                </div>
                                                : ''
                                            }
                                            
                                        </div>
                                        <div className="col-md-5">
                                            <div className="d-flex align-items-center justify-content-end">
                                                <span className='fs-14 me-2'>Show</span>
                                                <select className='fs-14 me-2 rounded-none bd-radius' value={limit} onChange={(e) => setLimit(e.currentTarget.value)}>
                                                    <option value="30">30</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="500">500</option>
                                                </select>
                                                {page===1 ? '' : <Link onClick={() => handlePrev()} className='ta-js-pagination-arrow'><FontAwesomeIcon icon={faAngleLeft} /></Link>}
                                                <span className='border py-1 px-2 fs-14 mx-2 pgnav-height'>Page {page} of {totalPages}</span>
                                                {page===totalPages ? '' : <Link onClick={() => handleNext()} className='ta-js-pagination-arrow'><FontAwesomeIcon icon={faAngleRight} /></Link>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                {
                                    jobSeekers.map(jobSeeker => {
                                        let req_title = ''
                                        let client_name = ''                                        
                                        let selectionStatus = ''
                                        let comment = ''
                                        let selectionInterviewDate = ''
                                        let selectionInterviewTime = ''

                                        if(jobSeeker.selection_last_log){
                                            // console.log('vcxcvxcvcxv')
                                            selectionStatus = jobSeeker.selection_last_log.selection_process_status ? jobSeeker.selection_last_log.selection_process_status.name : ''
                                            req_title = jobSeeker.selection_last_log.requirement ? jobSeeker.selection_last_log.requirement.title: ''
                                            client_name = jobSeeker.selection_last_log.requirement && jobSeeker.selection_last_log.requirement.client ? jobSeeker.selection_last_log.requirement.client.name : ''
                                            comment = jobSeeker.selection_last_log.comment ? jobSeeker.selection_last_log.comment : ''

                                            if(jobSeeker.selection_last_log.interviewDate){
                                                selectionInterviewDate = moment(jobSeeker.selection_last_log.interviewDate).format('DD-MMM-YYYY')
                                                selectionInterviewTime = jobSeeker.selection_last_log.interviewStartTime !== null && jobSeeker.selection_last_log.interviewEndTime !== null ? jobSeeker.selection_last_log.interviewStartTime + ' - ' + jobSeeker.selection_last_log.interviewEndTime  : ''
                                                console.log("selectionInterviewDate",selectionInterviewDate)
                                            }
                                        }
                                        
                                        // let preferredLocations = jobSeeker.preferredLocations
                                        return (
                                            <div className="ta-js-tile">
                                                <div className='row'>
                                                    <div className='col-md-8'>
                                                        <div className="ta-js-content">
                                                            <h5>
                                                                <input type="checkbox" className='mr-3' value={jobSeeker.id} name={jobSeeker.id} onChange={handleCheckProfile} checked={checkedProfiles.includes(jobSeeker.id)} style={{marginRight: '8px'}}/>
                                                                
                                                                <Link to={'/edit_job_seeker?id='+jobSeeker.id} target='_blank'>{jobSeeker.name.toUpperCase()}</Link>
                                                                <Link to={'https://api.whatsapp.com/send?phone=91'+jobSeeker.phone+'&text=HELLO '+jobSeeker.name.toUpperCase()} target='_blank' className='whatsapp-icon'><FontAwesomeIcon icon={faWhatsapp}/></Link>
                                                            </h5>
                                                            
                                                            <div className='ta-js-overview'>
                                                                <span><FontAwesomeIcon icon={faMobileAlt}/> {jobSeeker.phone}</span>
                                                                <span><FontAwesomeIcon icon={faEnvelope}/> {jobSeeker.email}</span>
                                                                {
                                                                    formatExperience(jobSeeker.totalExpYear, jobSeeker.totalExpMonth) !== '' ? 
                                                                    <span><FontAwesomeIcon icon={faBriefcase}/> {formatExperience(jobSeeker.totalExpYear, jobSeeker.totalExpMonth)}</span>
                                                                    : ''
                                                                }
                                                                
                                                                {
                                                                    jobSeeker.annualSalaryLakhs !== null && jobSeeker.annualSalaryThousands > 0 ? 
                                                                    <span><FontAwesomeIcon icon={faWallet}/> {jobSeeker.annualSalaryLakhs+ '.' +jobSeeker.annualSalaryThousands} LPA</span>
                                                                    : jobSeeker.annualSalaryLakhs !== null && jobSeeker.annualSalaryThousands <= 0 ? 
                                                                    <span><FontAwesomeIcon icon={faWallet}/> {jobSeeker.annualSalaryLakhs} LPA</span>
                                                                    : ''
                                                                }
                                                                {
                                                                    jobSeeker.currentLocation !== '' ? 
                                                                    <span><FontAwesomeIcon icon={faMapMarkerAlt}/> {jobSeeker.currentLocation.label}</span>
                                                                    : ''
                                                                }                                                        
                                                                
                                                            </div>
                                                            <div className='ta-js-details'>
                                                                <div className='row'>
                                                                    <div className='col-md-3'>
                                                                        <label>Current</label>
                                                                    </div>
                                                                    <div className='col-md-9'>
                                                                        <span>{jobSeeker.currCompanyDesgination} - {jobSeeker.currCompanyName}</span>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-3'>
                                                                        <label>Previous</label>
                                                                    </div>
                                                                    <div className='col-md-9'>
                                                                        <span>{jobSeeker.prevEmployerDesgination} - {jobSeeker.prevEmployerName}</span>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-3'>
                                                                        <label>Education</label>
                                                                    </div>
                                                                    <div className='col-md-9'>
                                                                        <span>{jobSeeker.pgDegree} - {jobSeeker.pgUniversity}</span>
                                                                    </div>
                                                                </div>
                                                                {/* <div className='row'>
                                                                    <div className='col-md-3'>
                                                                        <label>Pref Location</label>
                                                                    </div>
                                                                    <div className='col-md-9'>
                                                                        <span>
                                                                        {
                                                                            preferredLocations && preferredLocations.map(preferredLocation=>{
                                                                                return(
                                                                                    preferredLocation.label + ', '
                                                                                )
                                                                            })
                                                                        }
                                                                        </span>
                                                                    </div>
                                                                </div> */}
                                                                <div className='row'>
                                                                    <div className='col-md-3'>
                                                                        <label>Department & Role</label>
                                                                    </div>
                                                                    <div className='col-md-9'>
                                                                        <span>{jobSeeker.functionalArea !== '' ? jobSeeker.functionalArea.label : ''}</span>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-3'>
                                                                        <label>Industry</label>
                                                                    </div>
                                                                    <div className='col-md-9'>
                                                                        <span>{jobSeeker.industry !== '' ? jobSeeker.industry.label : ''}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='ta-ja-actions'>                                                        
                                                            
                                                            <div className='mb-3'>
                                                                <button className='ta-btn ta-btn-reverse ta-btn-w-140' onClick={() => showSelectionProcessInfo(jobSeeker.id)}><FontAwesomeIcon icon={faCircleInfo}/> Selection Info</button>
                                                            </div>
                                                            <div className='mb-3'>
                                                                <Link to={'/edit_job_seeker?id='+jobSeeker.id} className='ta-btn ta-btn-reverse ta-btn-w-140' target='_blank'><FontAwesomeIcon icon={faPenToSquare}/> Edit Profile</Link>
                                                            </div>
                                                            
                                                            <div className='mb-3'>
                                                            {
                                                                jobSeeker.resume && jobSeeker.resume !== null && jobSeeker.resume.includes('https') ?
                                                                <Link to={jobSeeker.resume} target='_blank' className='ta-btn ta-btn-reverse ta-btn-w-140'><FontAwesomeIcon icon={faDownload} style={{marginLeft: '8px'}}/> Resume</Link>
                                                                : ''
                                                            }
                                                            </div>
                                                            <div >
                                                                <button className='ta-btn ta-btn-reverse ta-btn-w-140' onClick={() => handleAddComment(jobSeeker.id)}><FontAwesomeIcon icon={faComment}/> Add Comment</button>
                                                            </div>                                                            
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                                <div className={selectionStatus !== '' ? 'ta-js-selection' : 'd-none' }>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <h6>{selectionStatus}</h6>
                                                            <p>{comment}</p>
                                                        </div>
                                                        <div className='col'>
                                                            <h6>{req_title} - {client_name}</h6>
                                                        </div>
                                                        <div className={selectionInterviewDate !== '' ? 'col' : 'd-none' }>
                                                            <h6><FontAwesomeIcon icon={faCalendarAlt}/> {selectionInterviewDate}</h6>
                                                            <h6><FontAwesomeIcon icon={faClock}/> {selectionInterviewTime}</h6>
                                                        </div>
                                                    </div>                                         
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className="ta-js-pagination">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="d-flex align-items-center">
                                                <span className='total-heading'>Total: {total}</span>                                            
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="d-flex align-items-center justify-content-end">
                                                <span className='fs-14 me-2'>Show</span>
                                                <select className='fs-14 me-2 rounded-none bd-radius' value={limit} onChange={(e) => setLimit(e.currentTarget.value)}>
                                                    <option value="30">30</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="500">500</option>
                                                </select>
                                                {page===1 ? '' : <Link onClick={() => handlePrev()} className='ta-js-pagination-arrow'><FontAwesomeIcon icon={faAngleLeft} /></Link>}
                                                <span className='border py-1 px-2 fs-14 mx-2 pgnav-height'>Page {page} of {totalPages}</span>
                                                {page===totalPages ? '' : <Link onClick={() => handleNext()} className='ta-js-pagination-arrow'><FontAwesomeIcon icon={faAngleRight} /></Link>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center m-5 p-5'>
                                <Oval
                                    height={50}
                                    width={50}
                                    color="#0d3f67"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={isJobSeekerLoading}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#0d3f67"
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}

                                />
                            </div>                            
                        </div>
                    </div>
                </div>
            </section>
            <Info show={showInfo} setShow={setShowInfo} infoId={infoId}/>
            <AddComment show={showAddComment} setShow={setShowAddComment} getList={getList} addCommentId={addCommentId} setAddCommentId={setAddCommentId} requirements={requirements} selectionProcessList={selectionProcessList}/>
        </AdminLayout>
    )

}