import './Admin.css';
import React from 'react';
import { AdminHeader } from '../Header/AdminHeader';
import { AdminSidebar } from '../Siderbar/AdminSidebar';
import { AdminFooter } from '../Footer/AdminFooter';

export const AdminLayout = (props) => {
    const { children } = props;
    return (
        <>
            <AdminHeader />
            <AdminSidebar />
            <main id="main" className="main">
                { children }
            </main>
            <AdminFooter />
        </>
    )
}