import './Login.css';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Swal from 'sweetalert2';

const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup
      .string('Enter your password')
      .required('Password is required')
});

export const Login = () => {
    const navigate = useNavigate();    
    const [isError, setIsError] = useState(false);

    const formik = useFormik({
        initialValues:{
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);            
        }
    });

    const handleSubmit = (values) => {
        axios.post('/users/login', values).then((res) =>{
            if(res.data.success){
                localStorage.setItem('rmsAuthUser', JSON.stringify(res.data.data));
                navigate('/dashboard');
            }
            else{
                Swal.fire(res.data.message, '', 'error')
            }
        }).catch((error) => {
            Swal.fire(error.message, '', 'error')        
        });
    }

    return (
        <section className="min-vh-100 d-flex flex-column align-items-center justify-content-center py-4 ">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className="d-flex justify-content-center border-bottom pb-3">
                                    <Link to='/home' className="logo d-flex align-items-center w-auto">
                                        <img src="assets/img/logo1.jpg" alt="Talent Advisor" />
                                    </Link>
                                </div>
                                <div>
                                    <h5 className="card-title text-center mt-2 pb-0 fs-4">Login to Your Account</h5>
                                    <p className="text-center small">Enter your username & password to login</p>
                                </div>
                                <div className="error text-danger" style={{ display: isError === true ? 'block' : 'none' }}>Incorrect Email or password!</div>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className='row'>
                                        <div className="col-12 mb-3">
                                            <label className="form-label">Email</label>
                                            <div className="input-group has-validation">
                                                <span className="input-group-text" id="inputGroupPrepend"><i className="bi bi-person"></i></span>
                                                <input type="email" name="email" placeholder='Please enter your email' className="form-control" onChange={formik.handleChange} onBlur={formik.handleBlur} />                                                    
                                            </div>
                                            {formik.touched.email && formik.errors.email ? <div className="text-danger">{formik.errors.email}</div> : null}                                      
                                        </div>

                                        <div className="col-12">
                                            <label className="form-label">Password</label>
                                            <div className="input-group has-validation">
                                                <span className="input-group-text" id="inputGroupPrepend"><i className="bi bi-key"></i></span>
                                                <input type="password" name="password" placeholder='Please enter your password' className="form-control" onChange={formik.handleChange} onBlur={formik.handleBlur} />                                                    
                                            </div>                                                
                                            {formik.touched.password && formik.errors.password ? <div className="text-danger">{formik.errors.password}</div> : null}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-6">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe" />
                                                <label className="form-check-label" forHtml="rememberMe">Remember me</label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <p className="small text-right mt-1"><Link to='/forgot_password'>Forgot Password?</Link></p>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-0">
                                        <button type="submit" className="ta-btn ta-btn-primary ta-btn-block ft-uppercase">
                                            <i className="bi bi-box-arrow-in-right ft-sm mr-2"></i> Login
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}