import axios from 'axios';
import Swal from 'sweetalert2';

export const UploadCsv = ({getList, setIsJobSeekerUploading, ...rest}) => {
    const handleFilesChange = (e) => {
        setIsJobSeekerUploading(true)
        let file = e.target.files[0];
        console.log(file)
        if(file.type === 'text/csv'){
            const formData = new FormData();
            formData.append('uploadCsv',file)
            axios.post(`job_seekers/uploadViaCsv`, formData).then(async res => {
                setIsJobSeekerUploading(false)
                if(res.data.success){
                    Swal.fire(res.data.message, res.data.details, 'success').then(result => {
                        window.location.reload()
                    })
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            })
        }
        else{
            Swal.fire('Please upload only csv file', '', 'error')
        }
        
        
    }

    return (
        <>
        <form autoComplete="off"  encType="multipart/form-data">
            <div className="">
                <input type="file" className="form-control d-none" name='uploadcsv' id="uploadcsv" onChange={handleFilesChange}/>
                <label className='d-block pointor' for="uploadcsv">CSV</label>
            </div>
        </form>
        </>
        
        
    )
}

export default UploadCsv;