import React, { useEffect, useState }  from 'react';
import axios from "axios";
import * as yup from 'yup';
import Swal from 'sweetalert2'
import { useFormik } from 'formik';
import { AdminLayout } from '../Layout/AdminLayout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import S3 from 'react-aws-s3';
import { Oval } from 'react-loader-spinner';
window.Buffer = window.Buffer || require("buffer").Buffer;

const validationSchema = yup.object({
    name: yup.string('Enter the name').required('Name is required'),
    logo: yup.mixed().required('Logo is required'),
    description: yup.string('Enter the description').required('Description is required'),
    position: yup.number('Enter only number').required('Position is required')
});

export const AddClient = () => {
    const authUser = localStorage.getItem("rmsAuthUser");
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    if(user && user.role !== 'Admin'){
        navigate('/dashboard')
    }
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("id")

    let initialValues = {
        id: null,
        name: '',
        logo: '',
        description: '',
        position: ''
    }

    useEffect(() => {
        axios.get(`client?id=${id}`).then(res => {
            console.log(res);
            if(res.data.success){
                if(res.data.data){
                    formik.setValues(res.data.data)
                }
                
            }
        })        
    },[id > 0]);  
    
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        }
    });

    const handleSubmit = (values) =>{
        axios.post(`clients/create`, values).then(res => {
            if(res.data.success){
                Swal.fire(res.data.message, '', 'success').then(result => {
                    navigate('/clients');
                })                
            } 
            else{
                Swal.fire(res.data.message, '', 'error')
            }
        }).catch((error) => {
            // console.log(error.response.data)
            Swal.fire(error.response.data.message, '', 'error')
        });
    }

    const S3Config = {
        bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
        dirName: 'Clients', /* optional */
        region: process.env.REACT_APP_S3_REGION,
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
        // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
    }

    const handleLogoChange = (e) => {
        let file = e.target.files[0];
        // console.log(file)
        let fileName = 'C_' + new Date().getTime()
        const ReactS3Client = new S3(S3Config);
        ReactS3Client.uploadFile(file, fileName)
            .then((data) => {                
                // console.log(data)
                if (data.status === 204 && data.location !== '') {
                    formik.setFieldValue('logo', data.location)                    
                    console.log("success")
                }                
            })
        
        // console.log(formik.values)
    }
    
    return (
        <AdminLayout>
            <div className="row mb-2">
                <div className="col-6">
                    <div className="pagetitle">
                        <h1>{id ? 'Edit' : 'Add'} Client</h1>
                    </div>
                </div>
            </div>

            <section className="section dashboard">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-xxl-12 col-md-8">
                                <div className="card recent-sales overflow-auto">
                                    <div className="card-body">
                                        <form className="g-3 mt-3" autoComplete="off" onSubmit={formik.handleSubmit}>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Name<span className="text-danger">*</span></label>
                                                        <input type="text" name="name" className="form-control" value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                        {formik.touched.name && formik.errors.name ? <div className="text-danger">{formik.errors.name}</div> : null} 
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Description<span className="text-danger">*</span></label>
                                                        <textarea name="description" className="form-control" value={formik.values.description} onChange={formik.handleChange} onBlur={formik.handleBlur}></textarea>
                                                        {formik.touched.description && formik.errors.description ? <div className="text-danger">{formik.errors.description}</div> : null} 
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Position<span className="text-danger">*</span></label>
                                                        <input type="text" name="position" className="form-control" value={formik.values.position} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                        {formik.touched.position && formik.errors.position ? <div className="text-danger">{formik.errors.position}</div> : null} 
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <input type='file' name='logo' onChange={(e) => handleLogoChange(e) }/>
                                                    {formik.touched.logo && formik.errors.logo ? <div className="text-danger">{formik.errors.logo}</div> : null} 
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="text-end">
                                                    <button type='submit' className="ta-btn ta-btn-primary text-center"> <i className="bi bi-save mr-2"></i> <span> Save</span> </button>
                                                </div>
                                            </div>                                      
                                        </form>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </AdminLayout>
    );
}