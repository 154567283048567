import React, { useState } from 'react';
import PropTypes from "prop-types";
import { useEffect } from 'react';
import axios from 'axios';
import { SendBulkEmail } from './SendBulkEmail';

export const Communication = ({checkedProfiles, ...rest}) => {
    const [show, setShow] = useState(false);

    const handleBulkEmail = () => {
        setShow(true)
    }
    

    return (
        <>
        <div className="btn-group">
            <button type="button" className="ta-btn ta-btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                Send Message
            </button>
            <ul className="dropdown-menu dropdown-menu-end">
                <li><button className="dropdown-item" type="button" onClick={handleBulkEmail}>Email</button></li>
                <li><button className="dropdown-item" type="button">SMS</button></li>
                <li><button className="dropdown-item" type="button">Whatsapp</button></li>
            </ul>
        </div>
        <SendBulkEmail show={show} setShow={setShow} checkedProfiles={checkedProfiles}/>
        </>
    );
}

export default Communication;

Communication.propTypes = {
    checkedProfiles: PropTypes.array.isRequired
};