import './App.css';
import "./intercepter";
import { Route, Routes } from 'react-router-dom';
import { Login } from './components/Login/Login';
import { PublicRoutes } from './components/Routes/PublicRoutes';
import { ProtectedRoutes } from './components/Routes/ProtectedRoutes';
import { Dashboard } from './components/Dashboard/Dashboard';
import { UserList } from './components/User/List';
import { RequirementList } from './components/Requirement/List';
import { AddRequirement } from './components/Requirement/Add';
import { JobSeekerList } from './components/JobSeeker/List';
import { AddJobSeeker } from './components/JobSeeker/Add';
import { Home } from './components/Pages/Home';
import { About } from './components/Pages/About';
import { Jobs } from './components/Pages/Jobs';
import { Contact } from './components/Pages/Contact';
import { Profile } from './components/Setting/Profile';
import { ChangePassword } from './components/Setting/ChangePassword';
import { ForgotPassword } from './components/Login/ForgotPassword';
import { useEffect } from 'react';
import WebFont from 'webfontloader';
import { JobDetails } from './components/Pages/JobDetails';
import { FavouriteList } from './components/JobSeeker/FavouriteList';
import { MarketingTemplateList } from './components/MarketingTemplate/List';
import { AddMarketingTemplate } from './components/MarketingTemplate/Add';
import { ClientList } from './components/Client/List';
import { AddClient } from './components/Client/Add';

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins']
      }
    });
   }, []);
  return (
    <Routes>
      {/* <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/jobs" element={<Jobs />} />
      <Route path="/job_details" element={<JobDetails />} />
      <Route path="/contact" element={<Contact />} /> */}
      <Route path="/forgot_password" element={<ForgotPassword />} />

      <Route path="/" element={<PublicRoutes />}>        
        <Route path="/" element={<Login />} />
      </Route>
      <Route path="/" element={<ProtectedRoutes />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/users" element={<UserList />} />
        <Route path="/requirements" element={<RequirementList />} />
        <Route path="/add_requirement" element={<AddRequirement />} />
        <Route path="/edit_requirement" element={<AddRequirement />} />
        <Route path="/candidate_profiles" element={<JobSeekerList />} />
        <Route path="/applied_candidates" element={<JobSeekerList />} />
        <Route path="/favourite_profiles" element={<FavouriteList />} />
        <Route path="/add_job_seeker" element={<AddJobSeeker />} />
        <Route path="/edit_job_seeker" element={<AddJobSeeker />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/change_password" element={<ChangePassword />} />
        <Route path="/templates" element={<MarketingTemplateList />} />
        <Route path="/add_template" element={<AddMarketingTemplate />} />
        <Route path="/edit_template" element={<AddMarketingTemplate />} />
        <Route path="/clients" element={<ClientList />} />
        <Route path="/add_client" element={<AddClient />} />
        <Route path="/edit_client" element={<AddClient />} />
      </Route>
    </Routes>
  );
}

export default App;
