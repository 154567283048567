import { useEffect, useState } from "react";
import { AdminLayout } from "../Layout/AdminLayout";
import axios from "axios";
import { useFormik } from "formik";
import * as yup from 'yup';
import Swal from 'sweetalert2'
import { Oval } from "react-loader-spinner";

const validationSchema = yup.object({
    name: yup
      .string('Enter your name')
      .required('Name is required'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    phone: yup
      .string('Enter your phone number')
      .required('Phone number is required')
});

export const Profile = () => {
    // const authUser = localStorage.getItem("rmsAuthUser");
    // const user = JSON.parse(authUser);
    const [isProfileUpdating, setIsProfileUpdating] = useState(false)
    const [profile, setProfile] = useState()

    let initialValues = {
        id: null,
        name: '',
        email: '',
        phone: '',
        role: '',
    }

    useEffect(() => {
        getProfile();
    }, []);   

    const getProfile = async() => {
        await axios.get('/user')
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setProfile(result.data)
                formik.setValues(result.data)
            }
        })
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        }
    });

    const handleSubmit = (values) =>{
        setIsProfileUpdating(true)
        axios.put(`users/update`, values).then(res => {
            if(res.data.success){               
                Swal.fire(res.data.message, '', 'success').then(result => {
                    getProfile();                        
                })
            } 
            else{
                Swal.fire(res.data.message, '', 'error')
            }
            setIsProfileUpdating(false)
        }).catch((error) => {
            // console.log(error.response.data)
            Swal.fire(error.response.data.message, '', 'error')
            setIsProfileUpdating(false)
        });    
    }


    return (
        <AdminLayout>
            <div className="row d-flex align-items-center mb-2">
                <div className="col-8">
                    <div className="pagetitle">
                        <h1>Profile</h1>
                    </div>
                </div>
                <div className="col-4 text-right">
                    
                </div>
            </div>
            <section className="section profile">
                <div className="row">
                    {/* <div className="col-xl-4">
                        <div className="card">
                            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                <img src="assets/img/profile-img.png" alt="Profile" className="rounded-circle" />
                                <h2>Rajiv Gangwar</h2>
                                <h3>Frontend Developer</h3>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-xl-8">
                        <div className="card">
                            <div className="card-body pt-3">
                                <ul className="nav nav-tabs nav-tabs-bordered">
                                    <li className="nav-item">
                                        <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">Overview</button>
                                    </li>
                                    <li className="nav-item">
                                        <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit">Edit Profile</button>
                                    </li>
                                </ul>
                                <div className="tab-content pt-2">
                                    <div className="tab-pane fade show active profile-overview pt-3" id="profile-overview">                  
                                        {/* <h5 className="card-title">Profile Details</h5> */}
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label ">Name</div>
                                            <div className="col-lg-9 col-md-8">{profile && profile.name ? profile.name : ''}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Email</div>
                                            <div className="col-lg-9 col-md-8">{profile && profile.email ? profile.email : ''}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Phone</div>
                                            <div className="col-lg-9 col-md-8">{profile && profile.phone ? profile.phone : ''}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Role</div>
                                            <div className="col-lg-9 col-md-8">{profile && profile.role ? profile.role : ''}</div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade profile-edit pt-3" id="profile-edit">                 
                                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                                            {/* <div className="row mb-3">
                                                <label for="profileImage" className="col-md-4 col-lg-3 col-form-label">Profile Image</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <img src="assets/img/profile-img.png" alt="Profile" />
                                                    <div className="pt-2">
                                                        <a href="#" className="btn btn-primary btn-sm" title="Upload new profile image"><i className="bi bi-upload"></i></a>
                                                        <a href="#" className="btn btn-danger btn-sm" title="Remove my profile image"><i className="bi bi-trash"></i></a>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="row mb-3">
                                                <label for="" className="col-md-4 col-lg-3 col-form-label">Full Name</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input name="name" type="text" className="form-control" value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                    {formik.touched.name && formik.errors.name ? <div className="text-danger">{formik.errors.name}</div> : null} 
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label for="Email" className="col-md-4 col-lg-3 col-form-label">Email</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input name="email" type="email" className="form-control" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                    {formik.touched.email && formik.errors.email ? <div className="text-danger">{formik.errors.email}</div> : null} 
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label for="Phone" className="col-md-4 col-lg-3 col-form-label">Phone</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input name="phone" type="text" className="form-control" value={formik.values.phone} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                    {formik.touched.phone && formik.errors.phone ? <div className="text-danger">{formik.errors.phone}</div> : null}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 col-lg-3"></div>
                                                <div className="col-md-8 col-lg-9">
                                                    <button type="submit" className={isProfileUpdating ? 'd-none' : 'ta-btn ta-btn-primary'}>Save Changes</button>
                                                    <Oval
                                                        height={35}
                                                        width={35}
                                                        color="#0d3f67"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={isProfileUpdating}
                                                        ariaLabel='oval-loading'
                                                        secondaryColor="#0d3f67"
                                                        strokeWidth={2}
                                                        strokeWidthSecondary={2}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </AdminLayout>
    )
}