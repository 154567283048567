import React, { useEffect, useState }  from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

const changeDateFormat = (date) => {
    let newDate = ''
    if(date){
        newDate = new Date(date).toLocaleString()
    }
    return newDate
}

const timeConvert = (time) => {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  
    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
  }

export const Info = ({show, setShow, infoId, ...rest}) => {    

    const handleClose = () => setShow(false)
    const [logs, setLogs] = useState([])

    useEffect(() => {
        if(infoId > 0){
            axios.get(`selection_process_log?id=${infoId}`).then(res => {
                console.log(res);
                if(res.data.success){
                    setLogs(res.data.data)
                }
            })
        }
    }, [infoId])
    
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-lg" style={{zIndex: 9999}}>            
            <Modal.Header>
                <h5 className="modal-title">Selection Process History - {logs.length > 0 ? logs[0].job_seeker.name : ''}</h5>
                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                    {/* <span aria-hidden="true">&times;</span> */}
                </button>
            </Modal.Header>
            <Modal.Body>
                {/* <h5>Requirement 1</h5> */}
                <table className='table table-bordered'>
                    <thead>
                        <tr>                            
                            <th>Status</th>
                            <th>Comment</th>
                            <th>Requirement</th>
                            <th>Interview Date</th>
                            <th>Interview Timing</th>
                            <th>Created DateTime</th>
                            <th>Created By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            logs.map(log => {
                                return (
                                    <tr>                                        
                                        <td>{log.selection_process_status.name}</td>
                                        <td>{log.comment}</td>
                                        <td>{log.requirement ? log.requirement.title : ''}</td>
                                        <td>{ log.interviewDate !== null ? moment(log.interviewDate).format('DD-MMM-YYYY') : ''}</td>
                                        <td>{log.interviewStartTime !== null && log.interviewEndTime !== null ? timeConvert(log.interviewStartTime) +' to '+ timeConvert(log.interviewEndTime) : ''}</td>
                                        <td>{moment(log.createdAt).format('DD-MMM-YYYY')}<br/>{moment(log.createdAt).format('hh:ss a')}</td>
                                        <td>{log.user.name}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </Modal.Body>
            {/* <Modal.Footer>
                <button type="button" className="r-btn r-btn-base" onClick={handleClose}>Close</button>
            </Modal.Footer> */}
        </Modal>
    );
}

export default Info;
Info.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    infoId: PropTypes.number.isRequired
};