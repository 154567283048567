import axios from 'axios';
import S3 from 'react-aws-s3';
import Swal from 'sweetalert2';
window.Buffer = window.Buffer || require("buffer").Buffer;


export const Upload = ({getList, setIsJobSeekerUploading, ...rest}) => {
    
    const config = {
        bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
        dirName: 'Resume', /* optional */
        region: process.env.REACT_APP_S3_REGION,
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
        // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
    }

    const handleFilesChange = (e) => {
        setIsJobSeekerUploading(true)
        let file = e.target.files[0];
        console.log(file)
        let fileExt = file.name.split(/\.(?=[^\.]+$)/)[1];
        console.log("fileExt", fileExt)
        if(file.type === 'application/pdf' || file.type === 'application/msword' || fileExt === 'pdf' || fileExt === 'docx' || fileExt === 'doc'){
            let fileName = 'R_' + new Date().getTime()
            const ReactS3Client = new S3(config);
            ReactS3Client.uploadFile(file, fileName)
                .then((data) => {
                    
                    // console.log(data)
                    if (data.status === 204 && data.location !== '') {                        
                        axios.post(`job_seekers/uploadResume`, {fileUrl: data.location }).then(async res => {
                            setIsJobSeekerUploading(false)
                            if(res.data.success){
                                if(res.data.message === 'Email or phone could not find in resume'){
                                    Swal.fire(res.data.message, '', 'error').then(result => {
                                        // window.location.reload()
                                    })
                                }
                                else{
                                    Swal.fire(res.data.message, '', 'success').then(result => {
                                        window.location.reload()
                                    })
                                }
                                
                            } 
                            else{
                                Swal.fire(res.data.message, '', 'error')
                            }
                        }).catch((error) => {
                            // console.log(error.response.data)
                            Swal.fire(error.response.data.message, '', 'error')
                        });
                        console.log("success")
                    }
                    
                })
        }
        else{
            Swal.fire('Please upload pdf or word file', '', 'error')
            setIsJobSeekerUploading(false)
        }
        
    }

    return (
        <>
        <form autoComplete="off"  encType="multipart/form-data">
            <div className="">
                <input type="file" className="form-control d-none" id="inputGroupFile02" onChange={handleFilesChange}/>
                <label className="d-block pointor" for="inputGroupFile02">PDF/Word</label>
            </div>
        </form>
        </>
        
        
    )
}

export default Upload;