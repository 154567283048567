import S3 from 'react-aws-s3';
import Swal from 'sweetalert2';

export const DumpResumeToS3 = ({getList, setIsJobSeekerUploading, ...rest}) => {
    const config = {
        bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
        dirName: 'Dump', /* optional */
        region: process.env.REACT_APP_S3_REGION,
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
        // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
    }

    const handleFilesChange = async (e) => {
        setIsJobSeekerUploading(true)
        let files = e.target.files;
        console.log(files)
        
        let response = await uploadToS3(files)
        setIsJobSeekerUploading(false)
        
        Swal.fire('Resumes have been uploaded successfully', response, 'success')        
    }

    const uploadToS3 = (files) => {
        return new Promise(async (resolve) => {
            let uploaded = 0;
            let notUploaded = 0;
            for(let i = 0; files.length > i; i++){
                let file = files[i];
                console.log(file)
                let fileExt = file.name.split(/\.(?=[^\.]+$)/)[1];
                console.log("fileExt", fileExt)
                if(file.type === 'application/pdf' || file.type === 'application/msword' || fileExt === 'pdf' || fileExt === 'docx' || fileExt === 'doc'){
                    let fileName = 'RD_' + new Date().getTime()
                    const ReactS3Client = new S3(config);
                    await ReactS3Client.uploadFile(file, fileName)
                        .then((data) => {                        
                            // console.log(data)
                            if (data.status === 204 && data.location !== '') { 
                                console.log("success")
                                uploaded = uploaded + 1
                            }
                            else{
                                notUploaded = notUploaded + 1
                            }
                        })
                }
                else{
                    notUploaded = notUploaded + 1                
                }
            }
            let msg = 'Total Resumes: '+files.length+', Uploaded: '+uploaded+', Not Uploaded: '+notUploaded
            resolve(msg)
        })
    }

    return (
        <>
        <form autoComplete="off"  encType="multipart/form-data">
            <div className="">
                <input type="file" className="form-control d-none" name='dumpResumes' id="dumpResumes" onChange={handleFilesChange} multiple/>
                <label className='d-block pointor' for="dumpResumes">Dump Resumes</label>
            </div>
        </form>
        </>
        
        
    )
}

export default DumpResumeToS3;